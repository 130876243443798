import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fromYear: { id: null, item: "Select Year" },
  toYear: { id: null, item: "Select Year" },
  priceScenario: { id: null, item: "Select" },
  legislativeScenario: { id: null, item: "Select" },
  isPartialYear: false,
  selectAll: false,
};

const selectedYearSlice = createSlice({
  name: "selectedYears",
  initialState,
  reducers: {
    setFromYear: (state, action) => {
      state.fromYear = action.payload;
    },
    setToYear: (state, action) => {
      state.toYear = action.payload;
    },
    setPriceScenario: (state, action) => {
      state.priceScenario = action.payload;
    },
    setLegislativeScenario: (state, action) => {
      state.legislativeScenario = action.payload;
    },
    setIsPartialYear: (state, action) => {
      state.isPartialYear = action.payload;
    },
    setSelectAll: (state, action) => {
      state.selectAll = action.payload;
    }
  },
});

export const {
  setFromYear,
  setToYear,
  setPriceScenario,
  setLegislativeScenario,
  setIsPartialYear,
  setSelectAll,
} = selectedYearSlice.actions;
export default selectedYearSlice.reducer;
