import { createSlice } from "@reduxjs/toolkit";

const emissionSwitchCheckedSlice = createSlice({
  name: "emissionSwitchChecked",
  initialState: true,
  reducers: {
    setChecked(state, action) {
      return action.payload;
    },
  },
});

export const { setChecked } = emissionSwitchCheckedSlice.actions;

export default emissionSwitchCheckedSlice.reducer;
