import { createSlice } from "@reduxjs/toolkit";

const pdfDataSlice = createSlice({
  name: "pdfData",
  initialState: {},
  reducers: {
    setPdfData(state, action) {
      return action.payload;
    },
  },
});

export const { setPdfData } = pdfDataSlice.actions;

export default pdfDataSlice.reducer;
