import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({
  children,
  tooltipText,
  placement
}) => {


  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{tooltipText}</Tooltip>}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15], // Adjust the offset values as needed
            },
          },
        ],
      }}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
