import { getEnvironmentVariable } from "./utils";

export const reactAppConfig = {
  sfsfBaseUrl: getEnvironmentVariable("SFSF_BASE_URL"),
  poolBaseUrl: getEnvironmentVariable("POOL_BASE_URL"),
  MSG_INVALID_URL: "The URL you entered appears to be incorrect. Please go back to tool and select the scenario manager again",
  MSG_INVALID_IDENTIFIER: "The identifier provided is not valid. Please go back to tool and select the scenario manager again",
  MSG_EXPIRED_TOKEN: "Current Session is Expired, Please go back to tool and select the scenario manager again",
  MSG_NOT_AUTHORISED: "You do not have enough permission to access this Page, Please go back to tool and select the scenario manager again"

};
