import axiosIns from "./axios";


export const axiosServerPost = (url) => {
    return axiosIns({
        method: "POST",
        url: url,
    });
};

export const axiosServerGet = (url) => {
    return axiosIns({
        method: "GET",
        url: url,
    });
};