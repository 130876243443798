import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceScenario: { id: null, item: "Select" },
};

const selectedPriceScenarioSlice = createSlice({
  name: "selectedPriceScenarioItem",
  initialState,
  reducers: {
    setPricescenarioData: (state, action) => {
      state.priceScenario = action.payload;
    },
  },
});

export const { setPricescenarioData } = selectedPriceScenarioSlice.actions;
export default selectedPriceScenarioSlice.reducer;
