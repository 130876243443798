import React, { useEffect,useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  allPriceForecastScenario,
  getAllBasinByScenario,
  getAllBasins,
  getAllFuelTypes,
  updateTemplate,
  getAllFuelPriceByBasin,
} from "../api/data";
import { setPricescenarioData } from "../redux/reducers/selectedPriceScenario";
import { setSelectedBasins } from "../redux/reducers/selectedBasin";
import {
  setFuelFamily,
  setBasePrice,
  setInflationRate,
  setFuelOrigin,
  setPriceUnit,
  setFindFuelType,
  setBasinsList,
} from "../redux/reducers/selectedtemplateData";

import SelectBox from "./SelectBox";
import MtIcon from "../assets/mt.svg";
import MMbtuIcon from "../assets/MMBtu.svg";
import DeleteIcon from "../assets/trash.svg";
import TrashIcon from "../assets/delete.svg";
import AddIcon from "../assets/plus.svg";
import PopupIcon from "../assets/popup.svg";
import { toast } from "react-toastify";
import useNumericInput from "../hooks/useNumericInput";

import ErrorOverlaymodal from "../components/ErrorOverlayModal";

const AddFuelTab = ({ handleNextStep, handlePrevious, selectedBasin, setChartData, setLoading }) => {
  const dispatch = useDispatch();

  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );
  const basinData = useSelector((state) => state.selectedBasin);
  const { fuelFamily, basePrice, fuelOrigin, inflationRate, priceUnit, findFuelType } =
    useSelector((state) => state.selectedtemplateData);
  
  const selectedBasins = useSelector((state) => state.selectedBasin);

  const [priceScenarios, setPriceScenarios] = useState([]);
  const [showPriceScenarioError, setShowPriceScenarioError] = useState(false);
  const [allFuelData, setAllFuelData] = useState([]);

  const [fuelFamiles, setFuelFamiles] = useState([]);
  const [showFuelFamilesError, setShowFuelFamilesError] = useState(false);
  const [viewBasePrice, setViewBasePrice] = useState("");
  const [viewBasinPrice, setViewBasinPrice] = useState("");
  const [basins, setBasins] = useState([]);
  const [basin, setBasin] = useState({
    id: null,
    item: "Select",
  });

  const [showBasinError, setShowBasinError] = useState(false);
  const [showValueError, setShowValueError] = useState(false);
  const [value, setValue] = useState(null);

  const [fuelOrigins, setFuelOrigins] = useState([]);
  const [showFuelOriginError, setShowFuelOriginError] = useState(false);
  const [priceUnits, setPriceUnits] = useState([
    { id: 1, item: "/mt" },
    { id: 2, item: "/MMBtu" },
  ]);
  const [showPriceUnitError, setShowPriceUnitError] = useState(false);
  const [showBasePriceError, setShowBasePriceError] = useState(false);
  const [showInflationRateError, setShowInflationRateError] = useState(false);
  const [fuelOriginsMap, setFuelOriginsMap] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageBasin, setErrorMessageBasin] = useState("");
  const [viewInflationRate, setViewInflationRate] = useState("");

  const [open, setOpen] = useState(false);
  const [isTouchedInflation, setIsTouchedInflation] = useState(false);
  const [isTouchedBasePrice, setIsTouchedBasePrice] = useState(false);
  const [isTouchedBasinPrice, setIsTouchedBasinPrice] = useState(false);
  const inflationRef = useRef(null);
  const basePriceRef = useRef(null);
  const basinPriceRef = useRef(null);
  const { handleOnKeyDown } = useNumericInput();

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const showToast = (message, toastId) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: toastId,
    });
  };

  const getAllPriceScenarios = () => {
    allPriceForecastScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setPriceScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
      });
  };

  const getAllBasinList = () => {
    getAllBasins()
      .then((res) => {
        const data = res.data;
        const list = Object.keys(data).map((key) => ({
          id: data[key].id,
          item: data[key].basinName,
        }));
        setBasins(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
      });
  };

  const getAllFuelList = () => {
    getAllFuelTypes()
      .then((res) => {
        const data = res.data;
        setAllFuelData(data);
        const uniqueFuelFamilyNames = new Set();
        const uniqueFuelTypeNames = new Set();
        const fuelOriginsMapTemp = {};

        Object.keys(data).forEach((key) => {
          const { fuelTypeName, family } = data[key];

          if (family?.visibleInFE) {
            const fuelFamilyName = family.fuelFamilyName;

            if (fuelFamilyName) {
              uniqueFuelFamilyNames.add(fuelFamilyName);

              fuelOriginsMapTemp[fuelFamilyName] = fuelOriginsMapTemp[fuelFamilyName] || [];

              if (fuelTypeName) {
                fuelOriginsMapTemp[fuelFamilyName].push({ id: key, item: fuelTypeName });
                uniqueFuelTypeNames.add(fuelTypeName);
              }
            }
          }
        });
        const fuelFamiliesList = [...uniqueFuelFamilyNames].map((item, index) => ({
          id: index + 1,
          item: item,
        }));

        const fuelTypeList = [...uniqueFuelTypeNames].map((item, index) => ({
          id: index,
          item: item,
        }));
        setFuelOrigins(fuelTypeList);
        setFuelFamiles(fuelFamiliesList);
        setFuelOriginsMap(fuelOriginsMapTemp);
      })
      .catch((err) => {
        setError(error);
        setOpen(true);
        console.log("error get all fuel types", err);
      });
  };

  useEffect(() => {
    getAllPriceScenarios();
    getAllBasinList();
    getAllFuelList();
  }, []);

  useEffect(() => {
    if (fuelFamily) {
      if (fuelOriginsMap[fuelFamily.item]) {
        const filteredValues = fuelOriginsMap[fuelFamily.item];
        setFuelOrigins(filteredValues);
        if (filteredValues.length > 0) {
            dispatch(setFuelOrigin(filteredValues[0]));
        } else {
            setFuelOrigin(null);
        }
      } else {
        setFuelOrigins([]);
      }
    }
  }, [fuelFamily, fuelOriginsMap]);

  const handleScenarioChange = (item) => {
    dispatch(setPricescenarioData(item));
  };

  const handleNext = () => {
    if (priceScenario.id === null) {
      setShowPriceScenarioError(true);
    } else {
      handleNextStep();
    }
  };

  const handleInputChange = (e) => {

    let stringValue = e.target.value ? e.target.value.toString() : '';
    const maxPrice = 500;
    const maxDigitsBeforeDecimal = 3; 
    const errorMessageBasin = `Price must be between 0 to ${maxPrice}`;

    const regex = new RegExp(`^\\d{0,${maxDigitsBeforeDecimal}}(\\.\\d{0,2})?$`);

    if (!regex.test(stringValue)) {
      const [integerPart, decimalPart] = stringValue.split('.');
      let slicedValue = integerPart.slice(0, maxDigitsBeforeDecimal);
      if (decimalPart) {
        slicedValue += '.' + decimalPart.slice(0, 2);
      }
      stringValue = slicedValue;
    }
    const value = parseFloat(stringValue);
    if (isNaN(value) || value < 0 || value > maxPrice) {
      setValue("");
      setViewBasinPrice(stringValue);
      setErrorMessageBasin(errorMessageBasin);
    } else {
      setValue(value);
      setViewBasinPrice(stringValue);
      setErrorMessageBasin('');
    }
  };
  const handleChangeBasin = (item) => {
    setBasin(item);
    setShowBasinError(false);
  };


  const handlechangeAfterAdd = (e, basin) => {
    const updatedBasin = { ...basin, price: e.target.value };
    dispatch(setSelectedBasins({ ...basinData, [basin.id]: updatedBasin }));
  };

  const handleFuelFamily = (item) => {
    dispatch(setFuelFamily(item));
  };
  const handleChangePriceUnit = (item) => {
    dispatch(setPriceUnit(item));
  };

  const handleChangeFuelOrigin = (item) => {
    dispatch(setFuelOrigin(item));
  };


  const handleAddField = () => {
    if (basin && basin.id !== null && value !== null && value !== "") {
      const updatedItem = {
        id: basin.id,
        item: basin.item,
        price: value,
      };

      const updatedItems = { ...basinData, [basin.id]: updatedItem };

      dispatch(setSelectedBasins(updatedItems));
      setBasin({ id: null, item: "Select" });
      setValue("");
      setShowBasinError(false);
      setShowValueError(false);
      setViewBasinPrice("");
    } else {
      if (!basin || basin.id === null) {
        setShowBasinError(true);
        showToast(
            "Please add at least one Basin and a Delta.",
            "basin_delta");
      }
      if (value === null || value === "") {
        setShowValueError(true);
        showToast(
            "Please add basin price for delta",
            "basin_delta");
      }
    }
  };

  const handleDeleteBasin = (id) => {
    const { [id]: _, ...rest } = basinData;
    dispatch(setSelectedBasins(rest));
  };


  useEffect(() => {
    handleChangeBasePrice(priceUnit.value);
  }, [priceUnit]);

  const handleBasePriceInputChange = (event) => {
    handleChangeBasePrice(event.target.value);
  };

  const handleChangeBasePrice = (value) => {
    let stringValue = value ? value.toString() : '';
    const maxPrice = 5000;
    const maxDigitsBeforeDecimal = 4; 
    const errorMessage = `Base Price must be between 0 to ${maxPrice}`;

    const regex = new RegExp(`^\\d{0,${maxDigitsBeforeDecimal}}(\\.\\d{0,2})?$`);

    if (!regex.test(stringValue)) {
      const [integerPart, decimalPart] = stringValue.split('.');
      let slicedValue = integerPart.slice(0, maxDigitsBeforeDecimal);
      if (decimalPart) {
        slicedValue += '.' + decimalPart.slice(0, 2);
      }
      stringValue = slicedValue;
    }

      let numericValue = parseFloat(stringValue);

      if (isNaN(numericValue) || numericValue < 0 || numericValue > maxPrice) {
        dispatch(setBasePrice(""));
        setViewBasePrice(stringValue);
        setErrorMessage(errorMessage);
      } else {
        dispatch(setBasePrice(parseFloat(numericValue)));
        setViewBasePrice(stringValue);
        setErrorMessage('');
      }
  };

  const [error, setError] = useState("");

  const handleInflationRate = (event) => {
    let value = event.target.value;
    let stringValue = value ? value.toString() : '';
    if (parseFloat(stringValue) > 10 || parseFloat(stringValue) < -10) {
      stringValue = stringValue.slice(0,2)
    }

    if (stringValue.includes('.')) {
      const [integerPart, decimalPart] = stringValue.split('.');
      if (decimalPart.length > 2) {
        stringValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
      }
    }

    let parsedValue = parseFloat(stringValue);

    if (value === "" || isNaN(parsedValue) || parsedValue < -10 || parsedValue > 10) {
      dispatch(setInflationRate(null));
      setError("Inflation rate must be between -10% and 10%");
      setViewInflationRate("");
    } else {
      dispatch(setInflationRate(parsedValue / 100));
      setViewInflationRate(stringValue);
      setError("");
    }
  }

  const handleInflationFocus = (event) => {
    setIsTouchedInflation(true);
  }

  const handleBasePriceFocus = (event) => {
    setIsTouchedBasePrice(true);
  }
  
  const handleBasinPriceFocus = (event) => {
    setIsTouchedBasinPrice(true);
  }

  const handleClickOutside = (event) => {
    if (inflationRef.current && !inflationRef.current.contains(event.target)) {
      if (inflationRate === null || inflationRate === undefined || inflationRate === '' ) {
        setIsTouchedInflation(false);
      } else if (event.target.value >= -10 && event.target.value <= 10) {
        setIsTouchedInflation(false);
      }
    }
    if (basePriceRef.current && !basePriceRef.current.contains(event.target)) {
      if (basePrice === null || basePrice === undefined || basePrice === '') {
        setIsTouchedBasePrice(false);
      } else if(event.target.value >= 0 && event.target.value <= 5000){
        setIsTouchedBasePrice(false);
      }
    }
    if (basinPriceRef.current && !basinPriceRef.current.contains(event.target)) {
      if (event.target.value === null || event.target.value === undefined || event.target.value === '') {
        setIsTouchedBasinPrice(false);
      } else if(event.target.value >= 0 && event.target.value <= 5000){
        setIsTouchedBasinPrice(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const getFuelPrices = (priceScenarioId, basinId) => {
    console.log("basinId", basinId);
    getAllFuelPriceByBasin(priceScenarioId, basinId)
      .then((res) => {
        setChartData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("get all fuel prices by basin error", err);
      });
  }
  
  

  const handleAddTemplate = () => {
    const showErrorToast = (message, toastId) => showToast(message, toastId);

    if (!priceScenario.id) {
      showErrorToast("Please select price scenario");
      return;
    }

    if (!fuelFamily.id) {
      showErrorToast("Please select fuel family.", "fuel_family");
      return;
    }

    if (!fuelOrigin.id) {
      showErrorToast("Please select fuel origin.", "fuel_origin");
      return;
    }

    if (!basePrice || basePrice === "") {
      showErrorToast("Please add base price.", "base_price");
      return;
    }

    if (inflationRate === null || inflationRate === undefined || inflationRate === "") {
      showErrorToast("Please add inflation rate.", "basin_delta");
      return;
    }

    if (Object.keys(basinData).length === 0) {
      showErrorToast(
        "Please add at least one Basin and a Delta.",
        "basin_delta"
      );
      return;
    }
    if (
      selectedBasins &&
      Object.values(selectedBasins).some(
        basin => !basin.price || basin.price === null || basin.price === "" || isNaN(basin.price)
      )
    ) {
      showErrorToast("Please add price for the selected basin", "basin_delta");
    }

    if (error.length > 0) {
      return;
    }

    const findFuelType = allFuelData.find((item) => item.id)
    const fuelOriginName = (fuelOrigin?.item);
    const fuelFamilyId = allFuelData.find((item) => item?.id);
    let selectFuelFamilyId = 0
    allFuelData.some((fuel) => {
      if (fuel?.family?.fuelFamilyName === fuelFamily?.item && fuel?.fuelTypeName === fuelOriginName) {
        selectFuelFamilyId = fuel.id
        return true;
      }
      return false;
    });

    const additiveOffsetByBasin = Object.fromEntries(
      Object.entries(basinData)
        .filter(([key, value]) => value.id && value.price !== null && value.price !== "")
        .map(([key, value]) => [
          value.id,
          parseFloat(value.price),
        ])
    );


    if (Object.keys(additiveOffsetByBasin).length !== Object.keys(basinData).length) {
      return;
      } else {
        const quantityUnit = priceUnit.item === "/mt" ? "mt" : "MMBtu";

        const resultObject = {
          scenarioId: priceScenario.id,
          fuelTypeId: selectFuelFamilyId,
          basePrice: basePrice,
          priceChangeRate: inflationRate,
          quantityUnit: quantityUnit,
          additiveOffsetByBasin: additiveOffsetByBasin,
        };
      
      const getBasinsForScenario = (value) => {
        const id = parseInt(value);
          getAllBasinByScenario(id)
            .then((res) => {
              const data = res.data;
              const list = data.map((item) => ({
                id: item.id,
                item: item.basinName,
              }));
              dispatch(setBasinsList(list));
            })
            .catch((err) => {
              setError(err);
              setOpen(true);
            });
      }

        updateTemplate(resultObject)
          .then((res) => {
            toast.success("Price template has been added.", {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "update_price_template",
            });
            getBasinsForScenario(priceScenario.id);
            const firstBasinId = selectedBasin?.id || Object.keys(basinData)[0];
            getFuelPrices(priceScenario?.id, firstBasinId);
          })
          .catch((err) => {
            setError(err);
            setOpen(true);
            console.log("update fuel price template err", err);
          });
      }
  };

 

  useEffect(() => {
    getAllPriceScenarios();
  },[priceScenario])

  return (
    <>
      <main className="p-0">
        <div className="add-fuel-container">
          <div className="fuel-template-container bg-transparent">
            <div className="d-flex align-items-center mb-4">
              <span className="input-label custom-mr-2 stretch fs-14">Scenario Name</span>
              <div>
                <SelectBox
                  selectedItem={priceScenario}
                  listItems={priceScenarios}
                  setSelectedItem={(item) => handleScenarioChange(item)}
                  disabledOptions={[priceScenario]}
                  showError={showPriceScenarioError}
                  setShowError={setShowPriceScenarioError}
                  showAddElement={true}
                  addElementOptionName="Add New Scenario Name"
                  className="select-box-v2 custom-select-box"
                  identifier='priceForecast'
                />
              </div>
            </div>
            <div className="fuel-template d-flex align-items-top gap-4 flex-lg-nowrap flex-md-wrap">
              <div className="left-container p-4 border bg-transparent rounded-16">
                <div className="first-select-container d-flex gap-4 align-items-start">
                  <div className="fuel-family-container">
                    <p className="input-label fs-14">Fuel Family</p>
                    <SelectBox
                      selectedItem={fuelFamily}
                      listItems={fuelFamiles}
                      setSelectedItem={(item) => handleFuelFamily(item)}
                      disabledOptions={[fuelFamily]}
                      showError={showFuelFamilesError}
                      setShowError={setShowFuelFamilesError}
                      className="select-box-v2"
                    />
                  </div>
                  <div className="fuel-origin-container">
                    <p className="input-label fs-14">Fuel Origin</p>
                    <SelectBox
                      selectedItem={fuelOrigin}
                      listItems={fuelOrigins}
                      setSelectedItem={(item) => handleChangeFuelOrigin(item)}
                      disabledOptions={[fuelOrigin]}
                      showError={showFuelOriginError}
                      setShowError={setShowFuelOriginError}
                      className="select-box-v2"
                    />
                  </div>
                </div>
                <div className="second-input-container d-flex mt-3 gap-3 align-items-start">
                  <div className="base-year-input">
                    <p className="input-label fs-14">Base Price 2024</p>
                    <div className="mail-input pos-relative parameter">
                      <SelectBox
                        selectedItem={priceUnit}
                        listItems={priceUnits}
                        setSelectedItem={(item) => handleChangePriceUnit(item)}
                        disabledOptions={[priceUnit]}
                        showError={showPriceUnitError}
                        setShowError={setShowPriceUnitError}
                        className="input-select fs-14"
                        showSelect={false}
                      />
                      <div className="input-container dollar-input">
                        {(basePrice >= 0 && viewBasePrice !== '') ? <span className="dollar-symbol">$</span> : <span></span>}
                        <input ref={basePriceRef}
                          type="text"
                          name="eufmNonComliance"
                          placeholder="Enter Base Price"
                          value={viewBasePrice || ""}
                          className={`bg-white ${showBasePriceError ? "error-input" : ""}`}
                          onFocus={handleBasePriceFocus}
                          onChange={(e) => handleBasePriceInputChange(e)}
                          onKeyDown={(e) => {handleOnKeyDown(e) }}
                        />
                      </div>
                      {isTouchedBasePrice && errorMessage && <p className="error mb-0 h-auto">{errorMessage}</p>}
                    </div>
                  </div>
                  <div className="inflation-rate-input">
                    <p className="input-label">Yearly Inflation Rate</p>
                    <div className="common-input-v2">
                      <span>%</span>
                      <input ref={inflationRef}
                        type="number"
                        name="eufmNonComliance"
                        placeholder="Enter Yearly Inflation Rate"
                        className={`bg-white no-highlight ${
                          showInflationRateError ? "error-input" : ""
                        }`}
                        value={viewInflationRate || ''} 
                        onFocus={handleInflationFocus}
                        onChange={(e) => handleInflationRate(e)}
                        onKeyDown={(event) => {
                          const allowedKeyCodes = [8, 46];
                          const allowedCharacters = [
                            "-",
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            ".",
                          ];

                          if (
                            allowedKeyCodes.includes(event.keyCode) ||
                            allowedCharacters.includes(event.key) ||
                            (event.key === "." &&
                              event.target.value.indexOf(".") === -1)
                          ) {
                            return;
                          } else {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {isTouchedInflation && error && <p className="error mb-0 h-auto">{error}</p>}
                  </div>
                </div>
              </div>
              <div className="right-container p-4 border bg-transparent rounded-16">
                <div className="price-deltas-container">
                  <div className="title">
                    <p className="text-dark mb-0 fs-20">Price Deltas By Basin</p>
                  </div>
                  <div className="price-selection-container gap-3">
                    <div className="w-100">
                      <div className="d-flex w-100 gap-3"> 
                        <div className="select-basin-container">
                          <SelectBox
                            selectedItem={basin}
                            listItems={basins}
                            setSelectedItem={(item) => handleChangeBasin(item)}
                            disabledOptions={Object.keys(basinData).map((key) =>
                              parseInt(key)
                            )}
                            showError={showBasinError}
                            setShowError={setShowBasinError}
                            className="select-box-v2"
                          />
                        </div>
                        <div className="basin-value dollar-input">
                          <div className="mail-input pos-relative parameter">
                            <img
                              src={priceUnit.item === "/mt" ? MtIcon : MMbtuIcon}
                              alt=""
                            />
                            {(value >= 0 && viewBasinPrice !== '') ? <span className="dollar-symbol dollar-margin">$</span> :  <span></span>}
                            <input ref={basinPriceRef}
                              type="text"
                              placeholder="Enter Value"
                              name="Enter Value"
                              className={`bg-white ${showValueError ? "error-input" : ""}`}
                              onFocus={handleBasinPriceFocus}
                              value={viewBasinPrice || ""}
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              onKeyDown={(e) => {handleOnKeyDown(e) }}
                            />
                          </div>
                        </div>
                      </div>
                      {isTouchedBasinPrice && errorMessageBasin && <p className="error mb-0 h-auto text-end px-5">{errorMessageBasin}</p>}
                    </div>
                    <button
                      className="btn btn-primary add secondary"
                      onClick={handleAddField}
                    >
                      <img src={AddIcon} alt="" />
                    </button>
                  </div>
                  <div className={`selected-basins ${Object.keys(basinData).length > 0 ? 'with-border' : ''}`}>
                    {basinData &&
                      Object.keys(basinData).map((key) => {
                        const basin = basinData[key];
                        if (!basin) return null;
                        return (
                          <div className="selected-basin-data" key={basin.id}>
                            <div className="basin-name">
                              <img src={PopupIcon} alt="" />
                              <p>{basin.item}</p>
                            </div>
                            <div className="price pos-relative basin-price-input">
                              {basin.price && <span className="dollar-symbol">$</span>}
                              <input
                                type="text"
                                defaultValue={basin.price}
                                onChange={(e) => handlechangeAfterAdd(e, basin)}
                                className="w-100"
                                onKeyDown={(e) => {handleOnKeyDown(e) }}
                              />
                              <img
                                src={priceUnit.item === "/mt" ? MtIcon : MMbtuIcon}
                                alt="unit icon"
                                className="unit-icon"
                              />
                            </div>
                            <button
                              className="btn secondary basin-price-delete"
                              onClick={() => handleDeleteBasin(basin.id)}
                            >
                              <img src={TrashIcon} alt="" />
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="add-template-action mt-3">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={() => handleAddTemplate()}
              >
                <div className="d-flex items-center">
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" /> Add
                </div>
              </button>
            </div>
          </div>
        </div>
        <hr className="my-4"/>
        <div className="actions d-none">
          <button className="btn btn-secondary" onClick={handlePrevious}>
            Previous
          </button>
          <button className="btn btn-primary" onClick={handleNext}>
            Next
          </button>
        </div>
      </main>

      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      />
    </>
  );
};

export default AddFuelTab;
