import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedVessel:{ id: null, item: "Select" },
};

const selectedVesselSlice = createSlice({
  name: "selectedVesselItem",
  initialState,
  reducers: {
    setSelectedVesselData(state, action) {
      state.selectedVessel = action.payload;
    },
  },
});

export const { setSelectedVesselData } = selectedVesselSlice.actions;
export default selectedVesselSlice.reducer;
