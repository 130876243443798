import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import AppNavigation from "./AppNavigation";
import { useDispatch } from "react-redux";
import { getUserTokenByIdentifier } from "../api/data";
import { reactAppConfig } from "../config/reactAppConfig";
import { axiosServerGet } from "../api/serverRequestCtrl";
import axiosIns from "../api/axios";
import { setPermissions } from "../redux/reducers/permissionsSlice";
import ErrorOverlayModal from "../components/ErrorOverlayModal";
import useSession from "../hooks/useSession";

const MainNavigator = () => {
  const {isLoggedIn, setIsLoggedIn} = useContext(AuthContext);
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const { setData } = useSession();
;
  const dispatch = useDispatch();

  const handleClose = () => {
    setError("");
    setShowError(false)
  };
  setData()

  useEffect(() => {
    const identifier = localStorage.getItem("identifier");
    if (identifier != undefined ) {
      getUserTokenByIdentifier(identifier)
      .then((response) =>{
        if(response.data.accessToken != undefined){
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("identifier", response.data.identifier);
          localStorage.setItem("appName", response.data.toolName);
          localStorage.setItem("appUrl", response.data.toolUrl);

          setIsLoggedIn(true);
          setShowError(false)
          setError("")
          updateAxiosBaseURL()
          getOwnerPermission();
        }else{
          setShowError(true)
          setError(reactAppConfig.MSG_INVALID_IDENTIFIER);
        }
      }).catch((err) =>{
        setIsLoggedIn(false);
        setShowError(true)
        setError(reactAppConfig.MSG_INVALID_IDENTIFIER);
        console.error("Error Occurred while getting User Token")
      });
  
    }else{
      setShowError(true)
      setError(reactAppConfig.MSG_INVALID_URL)
    }
    const updateAxiosBaseURL = () =>{
      const appName = localStorage.getItem("appName")
      const url =  appName === "pool"?reactAppConfig.poolBaseUrl:reactAppConfig.sfsfBaseUrl;
      axiosIns.defaults.baseURL = url;
    }

    const getOwnerPermission = () => {
      axiosServerGet("/api/v2/userManagement/getCompany")
        .then((result) => {
          dispatch(setPermissions(result.data.owner.permissions));
        })
        .catch((err) => console.error("err get company name", err));
    };
  }, [setIsLoggedIn] );

  return <>{isLoggedIn ? <AppNavigation /> : <ErrorOverlayModal show={showError}
  handleClose={handleClose}
  errorMessage={error}/>}</>;
  };

export default MainNavigator;
