import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fuelFamily: { id: null, item: "Select" },
  fuelOrigin: { id: null, item: "Select" },
  basePrice: null,
  priceUnit: { id: 1, item: "/mt" },
  inflationRate: null,
  findFuelType: 0,
  basinList: [],
};

const selectedTemplateData = createSlice({
  name: "selectedTemplateData",
  initialState,
  reducers: {
    setFuelFamily: (state, action) => {
      state.fuelFamily = action.payload;
    },
    setFuelOrigin: (state, action) => {
      state.fuelOrigin = action.payload;
    },
    setBasePrice: (state, action) => {
      state.basePrice = action.payload;
    },
    setPriceUnit: (state, action) => {
      state.priceUnit = action.payload;
    },
    setInflationRate: (state, action) => {
      state.inflationRate = action.payload;
    },
    setFindFuelType: (state, action) => {
      state.findFuelType = action.payload;
    },
    setBasinsList: (state, action) => {
      state.basinList = action.payload;
    }
  },
});

export const {
  setFuelFamily,
  setFuelOrigin,
  setBasePrice,
  setInflationRate,
  setPriceUnit,
  setFindFuelType,
  setBasinsList,
} = selectedTemplateData.actions;
export default selectedTemplateData.reducer;
