import axios from "axios";
import { reactAppConfig } from "../config/reactAppConfig";

const axiosIns = axios.create({
  baseURL: reactAppConfig.poolBaseUrl,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onReceivedRefreshedToken = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const getRefreshToken = (refreshToken) => {
  const data = new FormData();
  data.append("refreshToken", refreshToken);
  const appName = localStorage.getItem("appName")
  const url =  appName === "pool"?reactAppConfig.poolBaseUrl:reactAppConfig.sfsfBaseUrl;
  return axios.request({
    method: "POST",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/regenerateAccessAndRefreshToken`,
    headers: {
      accept: "application/json",
    },
    data: data,
  });
};

axiosIns.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("accessToken");
    let refreshToken = localStorage.getItem("refreshToken");
    
    if (isTokenExpired(token)) {
      if (!isRefreshing) {
        isRefreshing = true;
        await getRefreshToken(refreshToken)
          .then((res) => {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("identifier", res.data.identifier);
            config.headers["Authorization"] = `Bearer ${res.data.accessToken}`;
            onReceivedRefreshedToken(res.data.accessToken)
          })
          .catch((error) => {
            console.error(
              "Error occurred while getting new access and refresh token:",
              error
            );
          });
          isRefreshing = false;
      } else {
      
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            resolve(config);
          });
        });
      
        return retryOriginalRequest;
      }
    } else {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  try {
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = tokenData.exp * 1000;
    const currentTime = new Date().getTime();

    return currentTime >= expirationTime;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
}

export default axiosIns;
