export function getEnvironmentVariable(name) {
    const value =
      window && window.env && window.env[name]
        ? window.env[name]
        : process.env[name];
    if (value === undefined || value === null) {
      throw new Error("Missing environment variable: " + name);
    }
    return value;
  }
  