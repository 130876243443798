import { createSlice } from "@reduxjs/toolkit";

const revenueValueSlice = createSlice({
  name: "revenueValue",
  initialState: {},
  reducers: {
    setFreeRevenueValue(state, action) {
      return action.payload;
    },
  },
});

export const { setFreeRevenueValue } = revenueValueSlice.actions;
export default revenueValueSlice.reducer;
