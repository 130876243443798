import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LegislativeScenario from "../pages/LegislativeScenario";
import FuelPriceForecast from "../pages/FuelPriceForecast";
import VesselEditor from "../pages/VesselEditor";
import "../css/main.css";
import ErrorOverlayModal from "../components/ErrorOverlayModal";
import { reactAppConfig } from "../config/reactAppConfig";
import VesselTypeEditor from "../pages/VesselTypeEditor";



export default function AppNavigation() {
  const permissions = useSelector((state) => state.permissions.permissions);
  
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const handleClose = () => {
    setError("");
    setShowError(false)
  };
  const canAccessRoute = (requiredPermission) => {
    const isAuthrorised =  permissions.some(
      (permission) =>
        permission.licensePermission.permissionName === requiredPermission
    );
    return isAuthrorised
  };

  const appName = localStorage.getItem("appName");
  document.title = `${appName} Scenario Manager`;

  return (
    <Routes>

      <Route
        path="/fuelpriceforecast/:identifier"
        element={<FuelPriceForecast />}
      ></Route>
      <Route
        path="/legislativescenario/:identifier"
        element={
          permissions.length > 0 &&
          canAccessRoute("EditLegislativeScenarios") ? (
            <LegislativeScenario />
          ) : (
            <ErrorOverlayModal show={true}
            handleClose={handleClose}
            errorMessage={reactAppConfig.MSG_NOT_AUTHORISED}/>
          )
        }
      ></Route>
      <Route
        path="/vesseleditor/:identifier"
        element={
          permissions.length > 0 &&
          canAccessRoute("EditVessels") ? (
            <VesselEditor />
          ) : (
            <ErrorOverlayModal show={true}
              handleClose={handleClose}
              errorMessage={reactAppConfig.MSG_NOT_AUTHORISED}/>
          )
        }
      ></Route>
      <Route
        path="/vesseltypeeditor/:identifier"
        element={
          permissions.length > 0 &&
          canAccessRoute("EditVesselTypes") ? (
            <VesselTypeEditor />
          ) : (
            <ErrorOverlayModal show={true}
              handleClose={handleClose}
              errorMessage={reactAppConfig.MSG_NOT_AUTHORISED}/>
          )
        }
      ></Route>
    </Routes>
  );
}
