import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EuEtsChart = ({ chartData }) => {
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (chartData && chartData.euetsinclusionRate) {
      const euFmChartData = chartData.euetsinclusionRate;

      const labels = Object.keys(euFmChartData);
      const data = Object.keys(euFmChartData[labels[0]]);

      const additionalObject = {
        label: "Inclusion of pollutants by year",
      };

      const newDataset = [
        additionalObject,
        ...data.map((i, index) => ({
          label: ` ${i}`,
          data: labels.map((year) => euFmChartData[year][i]),
          borderColor:
            i === "CO2" ? "#71A4F6" : i === "CH4" ? "#E2C767" : "#EB8282",
          backgroundColor:
            i === "CO2" ? "#71A4F6" : i === "CH4" ? "#E2C767" : "#EB8282",
          tension: 0.2,
          pointRadius: 1.5,
          borderWidth: data.length - index + 1,
          order: data.length - index,
        })),
      ];

      setDataset(newDataset);
      setLabel(labels);
    }
  }, [chartData]);

  const dataEuEts = {
    labels: label,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: null },
    layout: {
      padding: {
        top: 50,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = JSON.parse(context.formattedValue);
            return `  ${context.dataset?.label}-${value * 100}%`;
          },
        },
        xAlign: "center",
        yAlign: "top",
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: "circle",
          textAlign: "center",
          padding: 20,
          generateLabels: (chart) => {
            const originalLabels =
              ChartJS.defaults.plugins.legend.labels.generateLabels(chart);

            originalLabels.forEach((label) => {
              if (label.text === "Inclusion of pollutants by year") {
                label.pointStyle = false;
                label.hidden = null;
              }
            });

            return originalLabels;
          },
        },
      },

      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        // stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return value.toFixed(2) * 100 + "%";
          },
        },
      },
    },
  };

  return (
    <div className="h-100">
      {chartData && chartData?.euetsinclusionRate ? (
        <>
          <div className="chart-label eu-ets">
            {chartData?.legislationEstablished?.EUETSinclusionRate && (
              <p>Established Legislation</p>
            )}
          </div>
          {/* <div className="chart-bottom-title">
            <p>Inclusion of pollutants by year</p>
          </div> */}
          <Line options={options} data={dataEuEts} />
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Loading loading={chartData.length === 0} height={30} />
        </div>
      )}
    </div>
  );
};

export default EuEtsChart;
