import { createSlice } from "@reduxjs/toolkit";

const selectedVesselItemsSlice = createSlice({
  name: "selectedVesselItems",
  initialState: {},
  reducers: {
    setSelectedVesselItems(state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedVesselItems } = selectedVesselItemsSlice.actions;

export default selectedVesselItemsSlice.reducer;
