import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ImoIntensityChart = ({ chartData }) => {
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (chartData && chartData.imoIntensityLimit) {
      const years = Object.keys(chartData.imoIntensityLimit);
      const intensities = Object.values(chartData.imoIntensityLimit);
      const label = years.map((i) => i);
      const dataset = [
        {
          label: "Emissions intensity limit by year  ",
          data: intensities.map((intensity) => intensity),
          borderColor: "#E2C767",
          backgroundColor: "#E2C767",
          tension: 0.2,
          pointRadius: 1,
          borderWidth: 3,
        },
      ];

      setDataset(dataset);
      setLabel(label);
    }
  }, [chartData]);

  const dataCii = {
    labels: label,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = JSON.parse(context.formattedValue);
            return "  " + value.toFixed(2) + " [gCO2e/MJ]";
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        rtl: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: "circle",
          textAlign: "center",
          padding: 20,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return value.toFixed(1).toLocaleString();
          },
        },
        title: {
          display: true,
          text: "[gCO2e/MJ]",
          color: "#000000",
        },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="h-100">
      {chartData && chartData?.imoIntensityLimit ? (
        <>
          <div className="chart-label">
            {chartData?.legislationEstablished?.imoIntensityLimit && (
              <p>Established Legislation</p>
            )}
          </div>
          <Line options={options} data={dataCii} />
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Loading loading={chartData.length === 0} height={30} />
        </div>
      )}
    </div>
  );
};

export default ImoIntensityChart;
