import { useEffect , useContext} from "react";
import SessionContext from "../contexts/SessionContext";
import { useLocation } from "react-router-dom";
import { reactAppConfig } from "../config/reactAppConfig";
import axiosIns from "../api/axios";
import { getUserTokenByIdentifier } from "../api/data";

export default function useSession() {
  const { user, setUser } = useContext(SessionContext);
  const location = useLocation();

  useEffect(() => {
    const userData = localStorage.getItem("accessToken");
    if (userData) {
      setUser(userData);
    }

    window.addEventListener('beforeunload', handleRemoveToken);
    window.addEventListener('popstate', handleRemoveToken);
  }, [localStorage, setUser]);

  const setData = () => {
    const pathNameArray = location.pathname.split("/");
    const identifier = pathNameArray[2];
    if(identifier !== undefined){
      localStorage.setItem("identifier", identifier);
    }
  }
  
const handleRemoveToken = () => {
    localStorage.removeItem('appUrl');
    localStorage.removeItem('appName');
    localStorage.removeItem('identifier');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  return { user, setData, handleRemoveToken };
}
