import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../assets/closeIcon3.svg";
import SelectBox from "./SelectBox";
import AddIcon from "../assets/plus.svg";
import M3Icon from "../assets/m3.svg";
import TrashIcon from "../assets/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "../assets/upload.svg";
import { toast } from "react-toastify";
import {
  enginesByVesselId,
  energyConsumptionByVesselId,
  updateVessel,
  addFuelTank,
  addEngines,
  addEnergyConsumption,
  allVesselType,
  getAllFuelFamilies,
  getEngineSubType,
  getEngineType,
  getActivities,
  getAllEngineSubTypes,
  deleteEngines,
  deleteFuelTank,
  deleteEnergyConsumption,
  addVessel,
  uploadImage,
  allVessels,
} from "../api/data";
import useNumericInput from "../hooks/useNumericInput";
import {
  capitalizeAllLetters,
  formatNumber,
  formatNumberAllowedZero,
} from "../utils/formatter";
import { setSelectedVesselData } from "../redux/reducers/selectedVesselReducer";

export const CommonOverlayModal = ({
  showOverlayModal,
  onCloseOverlayModal,
  modalSection,
  vesselInfo,
  setVesselInfo,
  fuelTankData,
  energyConsumptionData,
  engineData,
}) => {
  const selectedVessel = useSelector(
    (state) => state.selectedVesselItem.selectedVessel
  );
  const [showSpin, setShowSpin] = useState(false);

  const [engineType, setEngineType] = useState([]);
  const [engineSubType, setEngineSubType] = useState([]);
  const enginesBom = [
    { id: 1, item: "1" },
    { id: 2, item: "2" },
    { id: 3, item: "3" },
    { id: 4, item: "4" },
  ];

  const dispatch = useDispatch();
  const [engineTypeList, setEngineTypeList] = useState([]);

  const [vesselActivity, setVesselActivity] = useState([]);
  const [vesselModelType, setVesselModelType] = useState([]);

  const [vesselType, setVesselType] = useState({ id: null, item: "Select" });
  const [mainFuel, setMainFuel] = useState({ id: null, item: "Select" });
  const [vesselName, setVesselName] = useState(vesselInfo?.vesselName);
  const [vesselTypeId, setVesselTypeId] = useState(vesselInfo?.vesselType.id);
  const [fuelFamilyId, setFuelFamilyId] = useState(vesselInfo?.fuelFamily.id);
  const [capEx, setCapEx] = useState(vesselInfo?.extraCapex || "");
  const [rotationValue, setRotationValue] = useState({
    id: null,
    item: "Select",
  });
  const [hasScrubber, setHasScrubber] = useState(
    vesselInfo?.hasScrubber?.target?.value ?? vesselInfo?.hasScrubber
  );
  const [hasEGRSCR, setHasEGRSCR] = useState(vesselInfo?.hasEGRSCR);
  const { handleOnKeyDown } = useNumericInput();

  const [fuelCompatibility1, setFuelCompatibility1] = useState(null);
  const [fuelCompatibility2, setFuelCompatibility2] = useState(null);

  const [showErrorCompatibility1, setShowErrorCompatibility1] = useState(false);
  const [showErrorCompatibility2, setShowErrorCompatibility2] = useState(false);

  const [energyConsumptionActivityError, setEnergyConsumptionActivityError] = useState(false);
  const [energyConsumptionModelTypeError, setEnergyConsumptionModelTypeError] = useState(false);

  const [viewCapacity, setViewCapacity] = useState("");
  const [showCapacityError, setShowCapacityError] = useState(false);
  const [errorMessageCapacity, setErrorMessageCapacity] = useState("");
  const [isTouchedCapacity, setIsTouchedCapacity] = useState(false);

  const [fuelTanks, setFuelTanks] = useState([]);
  const [selectedEngineType, setSelectedEngineType] = useState({
    id: "",
    item: "Select",
  });
  const [selectedEngineSubType, setSelectedEngineSubType] = useState({
    id: "",
    item: "Select",
  });
  const [selectedBom, setSelectedBom] = useState({ id: null, item: "Select" });

  const [engines, setEngines] = useState([]);
  const [showEngineTypeError, setShowEngineTypeError] = useState(false);
  const [showEngineSubTypeError, setShowEngineSubTypeError] = useState(false);
  const [showBomError, setShowBomError] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState({
    id: "",
    item: "Select",
  });
  const [selectedModelType, setSelectedModelType] = useState({
    id: "",
    item: "Select",
  });
  const [energyRequirements, setEnergyRequirements] = useState("");
  const [energyConsumptions, setEnergyConsumptions] = useState([]);
  const [energyRequirementsAdd, setEnergyRequirementsAdd] = useState({});

  const [vesselTypeList, setVesselTypeList] = useState([]);
  const [fuelFamilyList, setFuelFamilyList] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [engineSubTypeList, setEngineSubTypeList] = useState([]);
  const [combineEngineSubTypeList, setCombineEngineSubTypeList] = useState([]);
  const isAllEnginesCalled = useRef(false);
  const isEditVesselModal = useRef(false);
  const [activityList, setActivityList] = useState([]);
  const [rotationList, setRotationList] = useState([
    { id: 1, item: "A" },
    { id: 2, item: "B" },
    { id: 2, item: "C" },
    { id: 2, item: "D" },
  ]);

  const [energyConsumptionsBody, setEnergyConsumptionsBody] = useState([]);
  const [fuelTankBody, setFuelTankBody] = useState([]);
  const [enginesBody, setEnginesBody] = useState([]);

  const [vesselNameError, setVesselNameError] = useState(null);
  const [capExError, setCapExError] = useState(null);
  const [rotationError, setRotationError] = useState(null);

  const [showRequirementError, setShowRequirementError] = useState(false);
  const [requirementError, setRequirementError] = useState(null);
  const [availableFuelFamily, setAvailableFuelFamily] = useState([]);
  const [availableEnginesModal, setAvailableEnginesModal] = useState([]);
  const [files, setFiles] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if(fileSizeInMB > 1){
        toast.error("Image size must be less than 1MB")
        return;
      }
    }
    const renamedFile = new File(
      [file],
      `vessel-image-${selectedVessel.id}-thumbnail.png`,
      {
        type: file.type,
      }
    );
    setFiles(renamedFile);
    if (renamedFile) {
      setSelectedFile(URL.createObjectURL(renamedFile));
    }
  };

  const handleUploadImage = (id) => {
    if (files) {
      uploadImage(files, id)
        .then((res) => {
          console.log(res.data);
          setShowSpin(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 500) {
            toast.error("Image for this vessel is already available");
          }
          setError(err);
          setOpen(true);
          console.log("error uploading image", err);
        });
        setFiles(null)
        setSelectedFile(null)
    }
  };

  const [deletedEngines, setDeletedEngines] = useState([]);
  const [deletedTanks, setDeletedTanks] = useState([]);
  const [deletedEnergyConsumptions, setDeletedEnergyConsumptions] = useState([]);
  
  const handleDeleteEngines = async (id) => {
    try {
      const res = await deleteEngines(id);
      console.log(res.data);
      setShowSpin(false);
      onCloseOverlayModal();
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("error deleting engines", err);
    }
    console.log("Deleted",id)
  };

  const handleDeleteFuelTank = async(id) => {
    try {
      const res = await deleteFuelTank(id);
      console.log(res.data);
      setShowSpin(false);
      onCloseOverlayModal();
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("error deleting fuel tank", err);
    }
  };

  const handleDeleteEnergyConsumption = async(id) => {
    try{
      const res = await deleteEnergyConsumption(id);
      console.log(res.data);
      setShowSpin(false);
      onCloseOverlayModal();
    }catch (err){
      setError(err);
      setOpen(true);
      console.log("error deleting energy consumption", err);
    }
  };

  const getEngineSubTypeList = () => {
    getAllEngineSubTypes()
      .then((result) => {
        const engineSubTypeList = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.estname,
        }));
        setCombineEngineSubTypeList(engineSubTypeList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log(err);
      });
  };

  const getEngineTypeList = () => {
    getEngineType()
      .then((result) => {
        const engineTypeList = result.data.map((v, index) => ({
          id: v?.id,
          item: capitalizeAllLetters(v?.etname),
        }));
        setEngineTypeList(engineTypeList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log(err);
      });
  };

  const getAllFuelFamily = () => {
    getAllFuelFamilies()
      .then((result) => {
        const fuelFamilyList = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.fuelFamilyName,
        }));
        setFuelFamilyList(fuelFamilyList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getAllVesselTypes = () => {
    allVesselType()
      .then((result) => {
        const vesselTypeList = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.vesselTypeName,
        }));
        setVesselTypeList(vesselTypeList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const handleSaveEngines = async() => {
    await Promise.all(
      deletedEngines?.map(async(engine) => {
        handleDeleteEngines(engine?.id);
      })
    );
    setDeletedEngines([]);
    if (!enginesBody && enginesBody.length === 0 && deletedEngines.length === 0 ) {
      toast.error("Please add or delete engines");
      return;
    }
    if(enginesBody.length > 0){
      addEngines(selectedVessel?.id, enginesBody)
      .then((res) => {
        console.log(res.data);
        toast.success("Engines information has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
        setEnginesBody([]);
        onCloseOverlayModal();
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error save engines", err);
      });
    }else{
      toast.success("Engines information has been updated.")
    }
    setEnginesBody([]);
    onCloseOverlayModal();
  };

  const handleSaveFuelTanks = async () => {
    const modifiedFuelTankBody = fuelTankBody.map((tank) => {
      const { id, ...rest } = tank;
      return isValidTimestamp(id) ? rest : { id, ...rest };
    });

    await Promise.all(
      deletedTanks?.map(async (deletedTank) => {
        const tankId = deletedTank[0]?.id;

        const isTankInFuelTankBody = fuelTankBody.some((tank) => tank.id === tankId);
        
        if (!isTankInFuelTankBody && !isValidTimestamp(tankId)) {
          // Delete the tank
          return handleDeleteFuelTank(tankId);
        }
      })
    );
    
    setDeletedTanks([]);

    if(fuelTankBody.length > 0){
      addFuelTank(selectedVessel?.id, modifiedFuelTankBody)
        .then((res) => {
          toast.success(
            "Fuel Tank details has been updated.",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "update_price_template",
            }
          );
          setShowSpin(false);
          setFuelTankBody([]);
          onCloseOverlayModal();
        })
        .catch((err) => {
          setFuelTankBody([]);
          setError(err);
          setOpen(true);
          console.log("error update scenario", err);
        });
    }else{
      toast.success("Fuel Tank details has been updated.")
    }
    onCloseOverlayModal();
  };

  const handleSaveEnergyConsumption = async () => {
    if (deletedEnergyConsumptions?.length > 0) {
      await Promise.all(
        deletedEnergyConsumptions?.map(async (energyConsumption) =>
          handleDeleteEnergyConsumption(energyConsumption?.id)
        )
      );
    }
    setDeletedEnergyConsumptions([]);

    const modifiedEnergyConsumptions = energyConsumptionsBody
      .filter((entry) => entry !== null || entry?.id !== null || entry?.id !== undefined)
      .map((entry) => ({
        ...entry,
        id: isValidTimestamp(entry?.id) ? null : entry?.id,
      }));

    const hasInvalidPowerRequirements = modifiedEnergyConsumptions.some(
      (entry) =>
        entry === undefined ||
        entry?.powerRequirement_Kw_hr === undefined ||
        entry?.powerRequirement_Kw_hr === null ||
        entry?.powerRequirement_Kw_hr === ""
    );

    const hasOutOfLimitRequirement = modifiedEnergyConsumptions.some(
      (entry) => {
        const powerRequirement = parseFloat(entry?.powerRequirement_Kw_hr);
        return powerRequirement > 1000000 || powerRequirement < 0;
      }
    );
    
    if (hasOutOfLimitRequirement) {
      if (!toast.isActive("requirement-limit")) {
        toast.error(`Requirement/hr must be less than ${formatNumber(1000000)}`, {
          toastId: "requirement-limit",
        });
      }
      return;
    } 
    if (hasInvalidPowerRequirements) {
      if (!toast.isActive("invalid-requirement")) {
        toast.error("Requirement/hr cannot be empty", {
          toastId: "invalid-requirement",
        });
      }
      return;
    }

    if(modifiedEnergyConsumptions.length > 0){    
      addEnergyConsumption(selectedVessel?.id, modifiedEnergyConsumptions)
        .then((res) => {
          console.log(res.data);
          toast.success("Energy consumption information has been updated.", {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            toastId: "update_price_template",
          });
          setShowSpin(false);
          onCloseOverlayModal();
        })
        .catch((err) => {
          setError(err);
          setOpen(true);
          console.log("error update energy consumption", err);
        });
    }else{
      toast.success("Energy consumption information has been updated.")
    }
    onCloseOverlayModal();   
  };

  const handleActivityChange = (item) => {
    setSelectedActivity(item);
  };

  const handleModelTypeChange = (modelTypeItem) => {
    const isPresent = energyConsumptions.some((energyItem) => {
      return modelTypeItem.item === energyItem.est.estname && selectedActivity.item === energyItem.va.name;
    });
  
    if (isPresent) {
      if (!toast.isActive("model-type-ec")) {
        toast.error("Model type already exists for this activity", {
          toastId: "model-type-ec"
        })
      }
      return;
    }
  
    setSelectedModelType(modelTypeItem);
  };

  const handleRequirementChange = (e) => {
    let value = e.target.value;

    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "0");
    }

    if (isNaN(value) || value < 0 || value > 1000000) {
      value = value.slice(0, 7);
      setShowRequirementError(true);
      setRequirementError(`Value must be less than ${formatNumber(1000000)}`);
      setEnergyRequirements(value);
    } else {
      setShowRequirementError(false);
      setEnergyRequirements(value);
      setRequirementError("");
    }
  };

  const handleRequirementFocus = () => {
    setShowRequirementError(false);
  };

  const handleAddEnergyConsumption = () => {
    if (
      selectedActivity.id &&
      selectedModelType.id &&
      energyRequirements !== "" &&
      energyRequirements >= 0 &&
      energyRequirements <= 1000000
    ) {
      setIsEnabled(true);
      const newEntry = {
        id: Date.now(),
        va: { name: selectedActivity?.item ?? "" },
        est: { estname: selectedModelType?.item ?? "" },
        powerReqKw: energyRequirements ?? 0,
      };

      const newEntryBody = {
        id: newEntry.id,
        vesselActivityId: selectedActivity.id,
        engineSubTypeId: selectedModelType.id,
        powerRequirement_Kw_hr: energyRequirements,
      };

      const updatedDeletedEnergyConsumptions = deletedEnergyConsumptions?.filter(deleted => {
        return !(
          deleted?.est?.id === selectedModelType.id &&
          deleted.powerReqKw === parseFloat(energyRequirements) &&
          deleted?.va?.id === selectedActivity.id
        );
      }) || [];

      setDeletedEnergyConsumptions(updatedDeletedEnergyConsumptions);
      setEnergyConsumptions([newEntry, ...energyConsumptions]);
      setEnergyConsumptionsBody([...energyConsumptionsBody, newEntryBody]);
      setSelectedActivity({ id: "", item: "Select" });
      setSelectedModelType({ id: "", item: "Select" });
      setEnergyRequirements("");
      setShowRequirementError(false);
    } else {
      const missingFields = [];

      if (!selectedActivity.id) missingFields.push("activity");
      if (!selectedModelType.id) missingFields.push("model type");
      if (energyRequirements === "") missingFields.push("requirements/hr");

      if (missingFields.length > 0) {
        if (missingFields.length === 3) {
          if (!toast.isActive("energy-consumption")) {
            toast.error("Please add activity, model type & requirements/hr", {
              toastId: "energy-consumption",
            });
          }
        } else {
          if (!toast.isActive("energy-data-consumption")) {
            toast.error(`Please add ${missingFields.join(" & ")}`, {
              toastId: "energy-data-consumption",
            });
          }
        }
        if (missingFields.includes("requirements/hr")) {
          setShowRequirementError(true);
        }
        if (missingFields.includes("model type")) {
          setEnergyConsumptionModelTypeError(true);
        }
        if (missingFields.includes("activity")) {
          setEnergyConsumptionActivityError(true);
        }
      } else {
        setShowRequirementError(false);
        setEnergyConsumptionActivityError(false);
        setEnergyConsumptionModelTypeError(false);
      }
    }
  };

  const handleRequirementAfterAdd = (index, value) => {
    const entry = energyConsumptions[index];
    if (value !== null && value !== "") {
      setIsEnabled(true);
    }

    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "0");
    }

    let vesselActivityId = entry?.va?.id;
    if (!vesselActivityId && entry?.va?.name) {
      const matchedActivity = activityList.find(
        (activity) => activity.item === entry.va.name
      );
      vesselActivityId = matchedActivity?.id || null;
    }

    let engineSubType = entry?.est?.id;
    if (!engineSubType && entry?.est?.estname) {
      const matchedModelType = combineEngineSubTypeList.find(
        (model) => model.item === entry.est.estname
      );
      engineSubType = matchedModelType?.id || null;
    }

    let id = entry?.id;

    setEnergyRequirementsAdd((prevRequirements) => ({
      ...prevRequirements,
      [index]: value,
    }));
    
    if (vesselActivityId && engineSubType) {
      setEnergyConsumptionsBody((prevEnergyConsumptions) => {
        const updatedEnergyConsumptions = prevEnergyConsumptions.map((entry) => {
          if (entry.id === id) {
            return {
              ...entry,
              powerRequirement_Kw_hr: value,
            };
          }
          return entry;
        });
        
        return updatedEnergyConsumptions;
      });
    }
  };

  const handleDeleteField = (index) => {
    const deletedItemId = energyConsumptions[index]?.id;
    if(!(isValidTimestamp(deletedItemId))) {
      if(deletedItemId != null){
        const deletedEnergyConsumption = energyConsumptions.find((energyConsumption) => energyConsumption.id === deletedItemId);
        setDeletedEnergyConsumptions([...deletedEnergyConsumptions,deletedEnergyConsumption]);
      }
    }
    setIsEnabled(true);
    const updatedConsumptions = energyConsumptions.filter(
      (_, i) => i !== index
    );
    setEnergyConsumptions(updatedConsumptions);

    const updatedConsumptionsBody = energyConsumptionsBody.filter(
      (item) => item.id !== deletedItemId
    );
    setEnergyConsumptionsBody(updatedConsumptionsBody);
  };

  const handleEngineTypeChange = (item) => {
    const engineTypeMap = {
      1: "me",
      2: "aux",
      3: "boilers",
    };

    const engineType = engineTypeMap[item.id] || "";

    getEngineSubType(engineType)
      .then((result) => {
        const dataArray = Array.isArray(result.data)
          ? result.data
          : Object.values(result.data);

        const engineSubTypeList = dataArray.map((v) => ({
          id: v?.id,
          item: v?.estname,
        }));

        setEngineSubTypeList(engineSubTypeList);
        if (dataArray.length === 0) {
          toast.error("No model type is available for selected engine");
        }
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log(err);
      });

    setSelectedEngineType(item);
    setShowEngineTypeError(false);
  };

  const getAllActivities = () => {
    getActivities().then((result) => {
      const activityList = result.data.map((v, index) => ({
        id: v?.id,
        item: v?.name,
      }));
      setActivityList(activityList);
    });
  };

  const handleEngineSubTypeChange = (selectedItem) =>
    handleEngineSubTypeChangeList(selectedItem);

  const handleBomChange = (item) => {
    setSelectedBom(item);
    setShowBomError(false);
  };

  const handleAddEngine = () => {
    if (selectedEngineType.id && selectedEngineSubType.id && selectedBom.id) {
      setIsEnabled(true);
      const newEngine = {
        est: {
          id: selectedEngineSubType.id ?? "",
          estname: selectedEngineSubType.item ?? "",
          myEngineClass: {
            etname: selectedEngineType.item ?? "",
          },
        },
        installed: selectedBom.item ?? false,
      };
      const newEngineBody = {
        estId: selectedEngineSubType.id,
        installed: selectedBom.item,
      };
      const setAvailableEnginesModalBody = {
        estName : selectedEngineSubType.item
      }

      const updatedDeletedEngines = deletedEngines?.filter(deleted => {
        return !(
          deleted?.est?.id === selectedEngineSubType.id &&
          deleted.installed === parseFloat(selectedBom.item) &&
          capitalizeAllLetters(deleted?.est?.myEngineClass?.etname) === selectedEngineType.item
        );
      }) || [];

      setDeletedEngines(updatedDeletedEngines);

      console.log(deletedEngines)

      setEngines([newEngine, ...engines]);
      setSelectedEngineType({ id: "", item: "Select" });
      setSelectedEngineSubType({ id: "", item: "Select" });
      setSelectedBom({ id: "", item: "Select" });
      setEnginesBody([...enginesBody, newEngineBody]);
      setAvailableEnginesModal([...availableEnginesModal, setAvailableEnginesModalBody])
    } else {
      const missingFields = [];

      if (!selectedEngineType.id) {
        setShowEngineTypeError(true);
        missingFields.push("engine type");
      }
      if (!selectedEngineSubType.id) {
        setShowEngineSubTypeError(true);
        missingFields.push("engine model type");
      }
      if (!selectedBom.id) {
        setShowBomError(true);
        missingFields.push("engine quantity");
      }

      if (missingFields.length > 0) {
        if (missingFields.length === 3) {
          if (!toast.isActive("engine-toaster")) {
            toast.error("Please add engine data", {
              toastId: "engine-toaster",
            });
          }
        } else {
          if (!toast.isActive("engine-data-toaster")) {
            toast.error(`Please add ${missingFields.join(", ")}`, {
              toastId: "engine-data-toaster",
            });
          }
        }
      }
    }
  };

  const handleDeleteEngine = (id, name, estId) => {
    if (id != null) {
      const deletedEngine = engines.find((engine) => engine.id === id);
    
      if (!deletedEngines.some((engine) => engine.id === deletedEngine.id)) {
        setDeletedEngines([...deletedEngines, deletedEngine]);
      }
    
      const updatedEnginesModal = availableEnginesModal.filter(
        (engine) => engine.estName !== deletedEngine.est.estname
      );
      setAvailableEnginesModal(updatedEnginesModal);
    }
    setIsEnabled(true);
    const updatedEngines = engines.filter((engine) => engine.est?.estname !== name);
    const updatedEnginesBody = enginesBody.filter((engine) => engine.estId !== estId);

    const deletedEngine = engines.find((engine) => engine.est?.estname === name);
    const updatedEnginesModal = availableEnginesModal.filter(
      (engine) => engine.estName !== deletedEngine.est.estname
    );
    setAvailableEnginesModal(updatedEnginesModal);


    setEngines(updatedEngines);
    setEnginesBody(updatedEnginesBody);
  };

  const handleEngineSubTypeChangeList = (selectedItem) => {
    if (availableEnginesModal.length === 0) {
      setSelectedEngineSubType(selectedItem);
      setShowEngineSubTypeError(false);
      return;
    }
    for (const enginesNames of availableEnginesModal) {
      if (selectedItem.item === enginesNames.estName) {
        toast.error(
          "The model type already available for selected engine type"
        );
        setSelectedEngineSubType({ id: "", item: "Select" });
        return;
      } else {
        setSelectedEngineSubType(selectedItem);
        setShowEngineSubTypeError(false);
      }
    }
  };

  const handleChangeFuelCompatibility = (selectedItem, compatibilityType) => {
    const otherCompatibilityType =
      compatibilityType === 1 ? fuelCompatibility2 : fuelCompatibility1;

    for (const fuelFamilyNames of availableFuelFamily) {
      if (
        selectedItem.item === fuelFamilyNames.name1 ||
        selectedItem.item === fuelFamilyNames.name2 ||
        (otherCompatibilityType !== null &&
          selectedItem.item === otherCompatibilityType.item)
      ) {
        toast.error("This fuel type is already assigned for this vessel.");
        compatibilityType === 1
          ? setFuelCompatibility1(null)
          : setFuelCompatibility2(null);
        return;
      }
    }

    if (
      otherCompatibilityType != null &&
      selectedItem.item === otherCompatibilityType.item
    ) {
      compatibilityType === 1
        ? setFuelCompatibility1(null)
        : setFuelCompatibility2(null);
      toast.error("This fuel type is already assigned for this vessel.");
    } else {
      compatibilityType === 1
        ? setFuelCompatibility1(selectedItem)
        : setFuelCompatibility2(selectedItem);
    }

    compatibilityType === 1
      ? setShowErrorCompatibility1(false)
      : setShowErrorCompatibility2(false);
  };

  const handleChangeFuelCompatibility1 = (selectedItem) =>
    handleChangeFuelCompatibility(selectedItem, 1);
  const handleChangeFuelCompatibility2 = (selectedItem) =>
    handleChangeFuelCompatibility(selectedItem, 2);

  const handleAddTank = () => {
    if (parseFloat(viewCapacity) <= 0 || parseFloat(viewCapacity) > 25000) {
      return;
    }

    if (
      (!fuelCompatibility1 && !fuelCompatibility2) ||
      !viewCapacity ||
      viewCapacity === ""
    ) {
      setIsEnabled(false);
      if (!toast.isActive("tank-toaster")) {
        toast.error("Please add tank capacity", { toastId: "tank-toaster" });
      }
      return;
    } else if (!fuelCompatibility1 && !fuelCompatibility2) {
      setShowErrorCompatibility1(!fuelCompatibility1);
      setShowErrorCompatibility2(!fuelCompatibility2);
      if (!toast.isActive("tank-fuel-compatibility")) {
        toast.error("Please add tank fuel compatibility", {
          toastId: "tank-fuel-compatibility",
        });
      }
      return;
    } else if (viewCapacity === "") {
      if (!toast.isActive("tank-capacity")) {
        toast.error("Please add tank capacity", { toastId: "tank-capacity" });
      }
      return;
    }

    setIsEnabled(true);
    const newTank = {
      capacityM3: parseFloat(viewCapacity),
      id: Date.now(),
      sharedByFuelFamilies: [
        ...(fuelCompatibility1
          ? [
              {
                fuelFamilyName: fuelCompatibility1.item,
                id: fuelCompatibility1.id,
              },
            ]
          : []),
        ...(fuelCompatibility2
          ? [
              {
                fuelFamilyName: fuelCompatibility2.item,
                id: fuelCompatibility2.id,
              },
            ]
          : []),
      ],
    };

    setFuelTanks([ newTank, ...fuelTanks]);    
    const fuelFamilyId = [
      fuelCompatibility1?.id,
      fuelCompatibility2?.id,
    ].filter((id) => id !== null && id !== undefined);

    const newFuelTankBody = {
      id: newTank.id,
      fuelFamilyId: fuelFamilyId,
      capacitym3: newTank.capacityM3,
    };

    setAvailableFuelFamily([
      ...availableFuelFamily,
      {
        name1: fuelCompatibility1?.item ?? "Not Selected",
        name2: fuelCompatibility2?.item ?? "Not Selected",
      },
    ]);

    setFuelTankBody([...fuelTankBody, newFuelTankBody]);
    setFuelCompatibility1(null);
    setFuelCompatibility2(null);
    setViewCapacity("");
  };

  const handleCapacityFocus = () => {
    setIsTouchedCapacity(true);
  };

  const handleCapacityChange = (e) => {
    let stringValue = e.target.value ? e.target.value.toString() : "";
    const maxCapacity = 25000;
    const maxDigitsBeforeDecimal = 5;
    const errorMessageCapacity = `Capacity must be between 1 to ${maxCapacity}`;

    const regex = new RegExp(
      `^\\d{0,${maxDigitsBeforeDecimal}}(\\.\\d{0,2})?$`
    );

    if (!regex.test(stringValue)) {
      const [integerPart, decimalPart] = stringValue.split(".");
      let slicedValue = integerPart.slice(0, maxDigitsBeforeDecimal);
      if (decimalPart) {
        slicedValue += "." + decimalPart.slice(0, 2);
      }
      stringValue = slicedValue;
    }
    const value = parseFloat(stringValue);
    if (isNaN(value) || value <= 0 || value > maxCapacity) {
      setViewCapacity(stringValue);
      setErrorMessageCapacity(errorMessageCapacity);
    } else {
      setViewCapacity(stringValue);
      setErrorMessageCapacity("");
    }
  };

  const handleChangeAfterAdd = (e, tank, index) => {
    const inputValue = e.target.value.trim();
    let updatedTank;
  
    const capacityValue = inputValue === "" || isNaN(parseFloat(inputValue)) ? null : parseFloat(inputValue);
  
    if (capacityValue === null || capacityValue > 25000 || capacityValue <= 0) {
      setIsEnabled(false);
      if (!toast.isActive("capacity-value")) {
        toast.error("Capacity must be between 1 to 25000", {
          toastId: "capacity-value",
        });
      }
  
      updatedTank = { ...tank, capacityM3: capacityValue };
    } else {
      setIsEnabled(true);
  
      updatedTank = { ...tank, capacityM3: capacityValue };
  
      if (capacityValue !== tank.capacityM3) {
        const fuelFamilyId = [
          tank.sharedByFuelFamilies[0]?.id,
          tank.sharedByFuelFamilies[1]?.id,
        ].filter((id) => id !== null && id !== undefined);
  
        const newFuelTankBody = {
          id: tank.id,
          fuelFamilyId: fuelFamilyId,
          capacitym3: capacityValue,
        };
  
        setFuelTankBody((prevFuelTankBody) => {
          const existingIndex = prevFuelTankBody.findIndex(
            (item) => item.id === tank.id
          );
  
          if (existingIndex !== -1) {
            const updatedFuelTankBody = [...prevFuelTankBody];
            updatedFuelTankBody[existingIndex] = newFuelTankBody;
            return updatedFuelTankBody;
          } else {
            return [...prevFuelTankBody, newFuelTankBody];
          }
        });
      }
    }
  
    setFuelTanks((prevFuelTanks) => {
      const updatedFuelTanks = [...prevFuelTanks];
      updatedFuelTanks[index] = updatedTank;
      return updatedFuelTanks;
    });
  };  

  const isValidTimestamp = (id) => {
    return typeof id === "number" && id > 1_700_000_000_000;
  };

  const handleDeleteTank = (tankId) => {
    if(tankId != null){
      //Added deleted field in list
      const deletedTank = fuelTanks.find((tank) => tank.id === tankId);

      setAvailableFuelFamily((prevAvailable) =>
        prevAvailable.filter((fuel) =>
          !(
            (deletedTank.sharedByFuelFamilies[0]?.fuelFamilyName === fuel.name1) ||
            (deletedTank.sharedByFuelFamilies[1]?.fuelFamilyName === fuel.name2)
          )
        )
      );

      setDeletedTanks([...deletedTanks, [deletedTank]]);
    }

    setFuelTankBody((prevFuelTankBody) =>
      prevFuelTankBody.filter((tank) => tank.id !== tankId)
    );

    setIsEnabled(true);
    const updatedTanks = fuelTanks.filter((tank) => tank.id !== tankId);
    setFuelTanks(updatedTanks);
    setIsEnabled(true);
  };

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const getVesselType = () => {
    try {
      const vesselTypeData = {
        id: vesselInfo?.vesselType?.id,
        item: vesselInfo?.vesselType?.vesselTypeName,
      };
      setVesselType(vesselTypeData);
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("Error in getting vessel type data:", err);
    }
  };

  const getMainFuel = () => {
    try {
      const mainFuel = {
        id: vesselInfo?.fuelFamily?.id,
        item: vesselInfo?.fuelFamily?.fuelFamilyName,
      };
      setMainFuel(mainFuel);
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("Error in getting vessel type data:", err);
    }
  };

  const getEngines = (vesselId) => {
    enginesByVesselId(vesselId)
      .then((res) => {
        const engineType = res.data.map((s, index) => ({
          id: s?.est?.myEngineClass?.id,
          item: capitalizeAllLetters(s?.est?.myEngineClass?.etname),
        }));
        setEngineType(engineType);
        const engineSubType = res.data.map((s, index) => ({
          id: s?.est?.id,
          item: s?.est?.estname,
        }));
        setEngineSubType(engineSubType);
        const engineBom = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.installed,
        }));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in getting fuel tank", err);
      });
  };

  const getEnergyConsumption = (vesselId) => {
    energyConsumptionByVesselId(vesselId)
      .then((res) => {
        const vesselActivity = res.data.map((s, index) => ({
          id: s?.va?.id,
          item: s?.va?.name,
        }));
        setVesselActivity(vesselActivity);
        const modelType = res.data.map((s, index) => ({
          id: s?.est?.id,
          item: s?.est.estname,
        }));
        setVesselModelType(modelType);
        const newEnergyConsumptionEntries = res.data.map((s) => ({
          id: s?.id,
          vesselActivityId: s?.va?.id,
          engineSubTypeId: s?.est?.id,
          powerRequirement_Kw_hr: s?.powerReqKw,
        }));

        setEnergyConsumptionsBody(newEnergyConsumptionEntries);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in getting energy consumption", err);
      });
  };

  const clearVesselEditorData = () => {
    setViewCapacity("");
    setFuelCompatibility1(null);
    setFuelCompatibility2(null);
    setErrorMessageCapacity("");
    setSelectedEngineType({ id: "", item: "Select" });
    setSelectedEngineSubType({ id: "", item: "Select" });
    setSelectedActivity({ id: "", item: "Select" });
    setEnergyRequirements("");
    setSelectedModelType({ id: "", item: "Select" });
    setShowRequirementError(false);
    setShowEngineTypeError(false);
    setShowEngineSubTypeError(false);
    setShowBomError(false);
    setSelectedBom({ id: "", item: "Select" });
    setRequirementError("")
    setDeletedEngines([])
    setDeletedTanks([]);
    setEnergyConsumptionsBody([]);
    setEnergyRequirementsAdd({})
  };

  useEffect(() => {
    if (selectedVessel?.id) {
      if (modalSection === "energy") {
        getEnergyConsumption(selectedVessel.id);
      } else if (modalSection === "fuel") {
        getAllFuelFamily();
      } else if (modalSection === "engine") {
        getEngines(selectedVessel.id);
        getEngineTypeList();
      }
    }
    if (modalSection === "editVesselDetails") {
      getVesselType();
      getMainFuel();
      getAllVesselTypes();
      getAllFuelFamily();
    }
  }, [modalSection, selectedVessel]);

  const fetchEngineName = () => {
    return engines?.map((engine) => ({
      estName: engine.est?.estname || "Not Selected",
    }));
  };

  useEffect(()=> {
    setAvailableEnginesModal(fetchEngineName);
  },[engines])

  useEffect(() => {
    function fetchData() {
      setIsEnabled(false);
      if (modalSection === "energy") {
        setEnergyConsumptions(energyConsumptionData);
      } else if (modalSection === "fuel") {
        setFuelTanks(fuelTankData);
        setAvailableFuelFamily(fetchFuelFamilName);
      } else if (modalSection === "engine") {
        setEngines(engineData);
        setAvailableEnginesModal(fetchEngineName);
      }
    }

    const fetchFuelFamilName = () => {
      return fuelTankData?.map((tank) => ({
        name1: tank.sharedByFuelFamilies[0]?.fuelFamilyName || "Not Selected",
        name2: tank.sharedByFuelFamilies[1]?.fuelFamilyName || "Not Selected",
      }));
    };

    fetchData();
  }, [fuelTankData, engineData, energyConsumptionData]);

  const handleUpdateVessel = () => {
    const transformedData = {
      name: vesselName,
      vesselTypeId: vesselTypeId,
      fuelFamilyId: fuelFamilyId,
      capEx: parseFloat(capEx.replace(/,/g, "")),
      rotation: rotationValue?.item,
      hasScrubber: hasScrubber,
      hasEGRSCR: hasEGRSCR,
    };

    if (vesselInfo && vesselInfo?.id) {
      updateVessel(transformedData, vesselInfo.id)
        .then((res) => {
          console.log(res.data);
          toast.success("Vessel information has been updated.", {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            toastId: "update-vessel",
          });
          setShowSpin(false);
          handleUploadImage(vesselInfo?.id);
          onCloseOverlayModal();
        })
        .catch((err) => {
          const message = err?.response?.data?.message 
          if(err?.response?.data?.message === message){
            toast.error(message, {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "add-new-vessel",
            });
          }
          setError(err);
          setOpen(true);
          console.log("error update scenario", err);
        });
    } else {
      addVessel(transformedData)
        .then((res) => {
          if(res.data === ""){
            toast.error("Vessel name already available", {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "add-new-vessel",
            });
            return
          }
          dispatch(setSelectedVesselData({
                id: res.data.id,
                item: res.data.vesselName,
              }));
          toast.success("Vessel information has been added successfully", {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            toastId: "add-new-vessel",
          });
          setShowSpin(false);
          handleUploadImage(res.data.id);
          onCloseOverlayModal(res.data.id);
        })
        .catch((err) => {
          const message = err?.response?.data?.message 
          if(err?.response?.data?.message === message){
            toast.error(message, {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "add-new-vessel",
            });
          }
          setError(err);
          setOpen(true);
          console.log("error update scenario", err);
        });
    }
  };

  const handleVesselNameChange = (e) => {
    let name = e.target.value;

    if (name.trim() === "") {
      setVesselNameError("Vessel Name cannot be empty");
      setVesselName("");
    } else if (name.length > 20) {
      name = name.slice(0, 20);
      setVesselNameError("Name must be less than 20 characters");
      setVesselName(name);
    } else {
      setVesselNameError("");
      setVesselName(name);
    }
  };

  const handleVesselTypeChange = (item) => {
    setVesselType(item);
    setVesselTypeId(item.id);
  };

  const handleMainFuelChange = (item) => {
    setMainFuel(item);
    setFuelFamilyId(item.id);
  };

  const handleCapExChange = (e) => {
    const capEx = e.target.value.replace(/,/g, "");
    const parsedCapex = parseFloat(capEx);

    if (isNaN(parsedCapex) || parsedCapex < 0 || parsedCapex > 250000000) {
      setCapEx(e.target.value);
      setCapExError(
        parsedCapex === 0
          ? ""
          : `Enter CapEx between 0 to ${formatNumber("250000000")}`
      );
    } else {
      setCapEx(formatNumberAllowedZero(parsedCapex));
      setCapExError("");
    }
  };

  const handleRotationChange = (item) => {
    setRotationValue(item);
  };

  const handleScrubberChange = (e) => {
    setHasScrubber(e.target.checked);
  };

  const handleEGRSCRChange = (e) => {
    setHasEGRSCR(e.target.checked);
  };

  const isButtonEnabled = () => {
    return (
      vesselName &&
      vesselName.trim() !== "" &&
      vesselTypeId &&
      fuelFamilyId &&
      capEx &&
      rotationValue &&
      !rotationError &&
      !capExError &&
      !vesselNameError
    );
  };

  useEffect(() => {
    if(selectedEngineType?.id === "" && selectedEngineType?.item === "Select"){
      setEngineSubTypeList([]);
    }
  }, [selectedEngineType])

  useEffect(() => {
    if (energyConsumptionData?.length > 0 && modalSection === "energy") {
      setEnergyConsumptions(
        energyConsumptionData.map((entry) => ({
          ...entry,
          energyRequirementsAdd: entry.powerReqKw || "",
        }))
      );
    }
  }, [energyConsumptionData]);

  useEffect(() => {
    if (modalSection === "energy" && !isAllEnginesCalled.current) {
      getEngineSubTypeList();
      getAllActivities();
      isAllEnginesCalled.current = true;
    }
  }, [modalSection]);

  useEffect(() => {
    if (modalSection === "editVesselDetails" && !isEditVesselModal.current) {
      setCapEx(formatNumberAllowedZero(vesselInfo?.extraCapex));
      setVesselName(vesselInfo?.vesselName);
      setVesselTypeId(vesselInfo?.vesselType?.id || null);
      setVesselType({
        id: vesselInfo?.vesselType?.vesselTypeId || null,
        item: vesselInfo?.vesselType?.vesselTypeName || "Select",
      });
      setFuelFamilyId(vesselInfo?.fuelFamily?.id || null);
      setMainFuel({
        id: vesselInfo?.fuelFamily?.id || null,
        item: vesselInfo?.fuelFamily?.fuelFamilyName || "Select",
      });
      const vesselRotation = vesselInfo?.rotation || "No Rotation";

      const matchedRotation = rotationList.find(
        (rotation) => rotation.item === vesselRotation
      );
      if (matchedRotation) {
        setRotationValue({
          id: matchedRotation.id,
          item: matchedRotation.item,
        });
      } else {
        setRotationValue({
          id: null,
          item: "No Rotation",
        });
      }
      setHasScrubber(vesselInfo?.hasScrubber || false);
      setHasEGRSCR(vesselInfo?.hasEGRSCR || false);
    }
  }, [vesselInfo, modalSection, isEditVesselModal.current]);

  return (
    <Modal
      show={showOverlayModal}
      aria-labelledby="engines-modal-title"
      aria-describedby="engine-modal-description"
      centered
      size="md"
      className="bg-none"
    >
      <Modal.Body className="p-1 common-overlay-modal">
        {modalSection === "engine" && (
          <>
            <div className="mb-2">
              <span className="editor-value-title">Engines</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEngines([]);
                  setEnginesBody([]);
                  onCloseOverlayModal();
                  clearVesselEditorData();
                  setSelectedBom({ id: null, item: "Select" });
                  setIsEnabled(false);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100">
              <div className="row me-2">
                <div className="col-3">
                  <p className="input-label pop-up-title mb-2">Engine Type</p>
                  <SelectBox
                    selectedItem={selectedEngineType}
                    listItems={engineTypeList}
                    disabledOptions={[]}
                    showError={showEngineTypeError}
                    setShowError={setShowEngineTypeError}
                    setSelectedItem={handleEngineTypeChange}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                  />
                </div>

                <div className="col-5">
                  <p className="input-label pop-up-title mb-2">Model Type</p>
                  <SelectBox
                    selectedItem={selectedEngineSubType}
                    listItems={engineSubTypeList}
                    setSelectedItem={handleEngineSubTypeChange}
                    disabledOptions={[]}
                    showError={showEngineSubTypeError}
                    setShowError={setShowEngineSubTypeError}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                  />
                </div>

                <div className="col-3">
                  <p className="input-label pop-up-title mb-2">Quantity</p>
                  <SelectBox
                    selectedItem={selectedBom}
                    listItems={enginesBom}
                    disabledOptions={[]}
                    setSelectedItem={handleBomChange}
                    showError={showBomError}
                    setShowError={setShowBomError}
                    showAddElement={false}
                    className="select-box-v2"
                  />
                </div>

                <div className="col-1 plus-btn-top">
                  <button
                    className="btn btn-primary add secondary"
                    onClick={handleAddEngine}
                  >
                    <img src={AddIcon} alt="" />
                  </button>
                </div>
              </div>

              <div
                className={`selected-engines ${
                  engines?.length > 0 ? "with-border" : ""
                } mt-3`}
              >
                {engines?.length > 0 && (
                  <div className="ht-9 overflow-y-auto overflow-x-hidden">
                    {engines?.map((engine, index) => (
                      <>
                        <div className="row mt-2 me-2 height-3">
                          <div className="col-3">
                            <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                              {capitalizeAllLetters(
                                engine.est?.myEngineClass.etname
                              )}
                            </div>
                          </div>

                          <div className="col-5">
                            <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                              {engine.est?.estname}
                            </div>
                          </div>

                          <div className="col-3">
                            <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                              {engine.installed}
                            </div>
                          </div>

                          <div className="col-1 pt-1">
                            <button
                              className="btn secondary p-1"
                              onClick={() => handleDeleteEngine(engine.id, engine.est?.estname, engine.est?.id)}
                            >
                              <img src={TrashIcon} alt="" />
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="actions bg-none text-end">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveEngines}
                disabled={!isEnabled}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}

        {modalSection === "fuel" && (
          <>
            <div className="mb-2">
              <span className="editor-value-title">Fuel Tanks</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFuelTanks([]);
                  setFuelTankBody([]);
                  onCloseOverlayModal();
                  clearVesselEditorData();
                  setIsEnabled(false);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100 px-3 py-2">
              <div className="w-100">
                <div className="row me-2">
                  <div className="col-3 mail-input pos-relative parameter">
                    <p className="input-label pop-up-title mb-2">
                      Total Capacity
                    </p>
                    <div className="mail-input pos-relative parameter">
                      <img src={M3Icon} alt="" />
                      <input
                        type="text"
                        placeholder="Enter Value"
                        name="Enter Capacity"
                        className={`rounded-5 outline-0 bg-white ${
                          showCapacityError ? "error-input" : ""
                        }`}
                        onFocus={handleCapacityFocus}
                        value={viewCapacity || ""}
                        onChange={handleCapacityChange}
                        onKeyDown={handleOnKeyDown}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <p className="input-label pop-up-title mb-2">
                      Fuel Compatibility 1
                    </p>
                    <SelectBox
                      selectedItem={
                        fuelCompatibility1 || { id: "", item: "Select" }
                      }
                      listItems={fuelFamilyList}
                      setSelectedItem={handleChangeFuelCompatibility1}
                      showError={showErrorCompatibility1}
                      setShowError={setShowErrorCompatibility1}
                      className="select-box-v2"
                    />
                  </div>
                  <div className="col-4">
                    <p className="input-label pop-up-title mb-2">
                      Fuel Compatibility 2
                    </p>
                    <SelectBox
                      selectedItem={
                        fuelCompatibility2 || { id: "", item: "Select" }
                      }
                      listItems={fuelFamilyList}
                      setSelectedItem={handleChangeFuelCompatibility2}
                      showError={showErrorCompatibility2}
                      setShowError={setShowErrorCompatibility2}
                      className="select-box-v2"
                    />
                  </div>
                  <div className="col-1 plus-btn-top">
                    <button
                      className="btn btn-primary add secondary"
                      onClick={handleAddTank}
                    >
                      <img src={AddIcon} alt="" />
                    </button>
                  </div>
                </div>
                {isTouchedCapacity && errorMessageCapacity && (
                  <p className="error mb-0 h-auto text-start">
                    {errorMessageCapacity}
                  </p>
                )}
              </div>
              <div
                className={`selected-tanks ${
                  fuelTanks?.length > 0 ? "with-border" : ""
                } mt-3`}
              >
                {fuelTanks?.length > 0 && (
                  <div className="ht-9 overflow-y-auto overflow-x-hidden">
                    {fuelTanks?.map((tank, index) => (
                      <div className="row mt-2 me-2 height-3" key={index}>
                        <div className="col-3">
                          <input
                            type="text"
                            value={tank.capacityM3}
                            onChange={(e) =>
                              handleChangeAfterAdd(e, tank, index)
                            }
                            className="w-100 model-padding rounded-5 outline-0 grey-border"
                            onKeyDown={handleOnKeyDown}
                          />
                          <img
                            src={M3Icon}
                            alt="unit icon"
                            className="m3-unit-icon"
                          />
                        </div>
                        <div className="col-4">
                          <div className="w-100 info-div grey-border">
                            {tank.sharedByFuelFamilies[0]?.fuelFamilyName}
                          </div>
                        </div>
                        <div className="col-4">
                          <div
                            className={`w-100 info-div outline-0 grey-border ${
                              tank.sharedByFuelFamilies[1] ? "" : "dark-grey"
                            }`}
                          >
                            {tank.sharedByFuelFamilies[1]?.fuelFamilyName ??
                              "Not Selected"}
                          </div>
                        </div>
                        <div className="col-1 pt-1">
                          <button
                            className="btn secondary p-1"
                            onClick={() => handleDeleteTank(tank.id)}
                          >
                            <img src={TrashIcon} alt="Delete Tank" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="actions bg-none text-end">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveFuelTanks}
                disabled={!isEnabled}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}
        {modalSection === "energy" && (
          <>
            <div className="mb-2">
              <span className="editor-value-title">Energy Consumption</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEnergyConsumptions([]);
                  setEnergyRequirementsAdd({});
                  setEnergyConsumptionsBody([]);
                  onCloseOverlayModal();
                  clearVesselEditorData();
                  setIsEnabled(false);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100">
              <div className="row me-2">
                <div className="col-3">
                  <p className="input-label pop-up-title mb-2">Activity</p>
                  <SelectBox
                    selectedItem={selectedActivity}
                    listItems={activityList}
                    disabledOptions={[]}
                    showError={energyConsumptionActivityError}
                    setShowError={setEnergyConsumptionActivityError}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                    setSelectedItem={handleActivityChange}
                  />
                </div>
                <div className="col-5">
                  <p className="input-label pop-up-title mb-2">Model Type</p>
                  <SelectBox
                    selectedItem={selectedModelType}
                    listItems={combineEngineSubTypeList}
                    setSelectedItem={handleModelTypeChange}
                    disabledOptions={[]}
                    showError={energyConsumptionModelTypeError}
                    setShowError={setEnergyConsumptionModelTypeError}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                  />
                </div>

                <div className="col-3 mail-input pos-relative parameter">
                  <p className="input-label pop-up-title mb-2">
                    Requirement/hr
                  </p>
                  <input
                    type="text"
                    className={`rounded-5 outline-0 bg-white ${
                      showRequirementError ? "error-input" : ""
                    }`}
                    placeholder="Enter Value"
                    value={energyRequirements}
                    onChange={handleRequirementChange}
                    onFocus={handleRequirementFocus}
                    onKeyDown={handleOnKeyDown}
                  />
                  {requirementError && (
                    <p className="error text-start mb-0">{requirementError}</p>
                  )}
                </div>
                <div className="col-1 plus-btn-top">
                  <button
                    className="btn btn-primary add secondary"
                    onClick={handleAddEnergyConsumption}
                  >
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                </div>
              </div>
              <div
                className={`selected-activities mt-2 ${
                  energyConsumptions?.length > 0 ? "with-border" : ""
                }`}
              >
                {energyConsumptions?.length > 0 && (
                  <div className="ht-9 overflow-y-auto overflow-x-hidden">
                    {energyConsumptions?.map((entry, index) => (
                      <div className="row mt-2 me-2 height-3" key={index}>
                        <div className="col-3">
                          <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                            {entry.va?.name}
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                            {entry.est?.estname}
                          </div>
                        </div>
                        <div className="col-3">
                          <input
                            type="text"
                            value={
                              energyRequirementsAdd[index] !== undefined
                                ? energyRequirementsAdd[index]
                                : entry?.powerReqKw ?? ""
                            }
                            className="w-100 model-padding rounded-5 outline-0 grey-border"
                            onKeyDown={handleOnKeyDown}
                            onChange={(e) =>
                              handleRequirementAfterAdd(
                                index,
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-1 pt-1">
                          <button
                            className="btn secondary p-1"
                            onClick={() => handleDeleteField(index)}
                          >
                            <img src={TrashIcon} alt="Delete Icon" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="actions bg-none text-end mt-2">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveEnergyConsumption}
                disabled={!isEnabled}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}

        {modalSection === "editVesselDetails" && (
          <>
            <div className="mb-2 bg-none">
              <span className="input-label">Vessel Editor</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onCloseOverlayModal();
                  setCapExError(null);
                  setVesselNameError(null);
                  setCapEx("");
                  setVesselName("");
                  setVesselTypeId(null);
                  setVesselType({
                    id: null,
                    item: "Select",
                  });
                  setFuelFamilyId(null);
                  setMainFuel({
                    id: null,
                    item: "Select",
                  });
                  setRotationValue("No Rotation");
                  setHasScrubber(false);
                  setHasEGRSCR(false);
                  setSelectedFile(null);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main justify-content-between gap-4 w-100">
              <div className="row d-flex align-items-center">
                <div className="wid-8">
                  <span className="">Vessel Name:</span>
                </div>
                <div className="col-5 d-flex flex-column m-0">
                  <input
                    className="input-box p-2 outline-0 m-0"
                    value={vesselName || ""}
                    onChange={(e) => handleVesselNameChange(e)}
                    placeholder="Enter Vessel Name"
                  />
                  {vesselNameError && (
                    <p className="error text-start mb-0">{vesselNameError}</p>
                  )}
                </div>
                {/* <div className="col-2">
                  <img src={DeleteIcon} alt="" />
                </div> */}
              </div>

              <div className="row d-flex align-items-center mt-4">
                <div className="wid-8 mb-3">
                  <span className="m-0">Vessel Type</span>
                </div>
                <div className="col-4 mb-3">
                  <SelectBox
                    selectedItem={vesselType}
                    listItems={vesselTypeList}
                    disabledOptions={[]}
                    showError={false}
                    setShowError={() => {}}
                    showAddElement={false}
                    className="select-box-v2 fixed-width-dropdown p-2"
                    setSelectedItem={handleVesselTypeChange}
                  />
                </div>

                <div className="wid-4 p-0 mb-3">
                  <span className="m-0">CapEx</span>
                </div>
                <div className="col-4 vessel-info-input span mb-3">
                  <span className="capExIcon">$</span>
                  <input
                    className="input-box-2 p-2 ps-4 m-0 outline-0 w-100"
                    value={capEx || ""}
                    onChange={handleCapExChange}
                    onKeyDown={handleOnKeyDown}
                  />
                  {capExError && (
                    <p className="error text-start mb-0">{capExError}</p>
                  )}
                </div>
              </div>

              <div className="row d-flex align-items-center mt-3">
                <div className="wid-8">
                  <span className="m-0">Main Fuel</span>
                </div>
                <div className="col-4">
                  <SelectBox
                    selectedItem={
                      mainFuel || { id: "", name: "Select an option" }
                    }
                    listItems={fuelFamilyList}
                    disabledOptions={[]}
                    showError={false}
                    setShowError={() => {}}
                    showAddElement={false}
                    className="select-box-v2 fixed-width-dropdown p-2"
                    setSelectedItem={handleMainFuelChange}
                  />
                </div>
                <div className="wid-4 p-0">
                  <span className="m-0">Rotation</span>
                </div>
                <div className="col-4">
                  <SelectBox
                    selectedItem={
                      rotationValue || { id: null, name: "No Rotation" }
                    }
                    listItems={rotationList}
                    disabledOptions={[]}
                    showError={false}
                    setShowError={() => {}}
                    showAddElement={false}
                    className="select-box-v2 fixed-width-dropdown p-2"
                    setSelectedItem={handleRotationChange}
                  />
                  {rotationError && (
                    <p className="error text-start mb-0">{rotationError}</p>
                  )}
                </div>
              </div>

              <div className="mt-3 d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-2">
                  <input
                    className="form-check-input outline-0 m-0"
                    type="checkbox"
                    checked={hasEGRSCR || false}
                    onChange={(e) => handleEGRSCRChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    EGR/SCR
                  </label>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <input
                    className="form-check-input outline-0 m-0"
                    type="checkbox"
                    checked={hasScrubber || false}
                    onChange={(e) => handleScrubberChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox2">
                    Scrubber
                  </label>
                </div>
              </div>
              <div className="mt-4 gap-2 mb-2">
                <span className="w-50">Vessel Image:</span>
                <div className="edit-file-input">
                  <img src={UploadIcon} alt="" />
                  <p className="mt-1 text-sm text-gray-600">
                    Browse and choose the files you want to upload from your
                    computer
                  </p>
                  <button className="upload-button" onClick={handleBrowseClick}>
                    Browse
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="edit-file-input outline-0"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                  />
                  {selectedFile && (
                    <div className="mt-2">
                      <p className="text-sm">Selected Image Preview:</p>
                      <img
                        src={selectedFile}
                        alt="Selected Preview"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="actions bg-none text-end">
                <button
                  className="btn btn-primary btn-lg fs-16"
                  onClick={async() => {
                    await handleUpdateVessel();
                  }}
                  disabled={!isButtonEnabled()}
                >
                  Save
                  {showSpin && (
                    <div className="spinner-border" role="status"></div>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
