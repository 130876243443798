import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  legislativeScenario: { id: null, item: "Select" },
};

const selectedLegislativeScenarioSlice = createSlice({
  name: "selectedLegislativeScenarioItem",
  initialState,
  reducers: {
    setLegislativeScenarioData: (state, action) => {
      state.legislativeScenario = action.payload;
    },
  },
});

export const { setLegislativeScenarioData } =
  selectedLegislativeScenarioSlice.actions;
export default selectedLegislativeScenarioSlice.reducer;
