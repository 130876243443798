import { createSlice } from "@reduxjs/toolkit";

const fuelPurchaseBySlice = createSlice({
  name: "fuelPurchaseBy",
  initialState: { id: 1, unit: "Expense [USD]" },
  reducers: {
    setSelctedPurchaseBy(state, action) {
      return action.payload;
    },
  },
});

export const { setSelctedPurchaseBy } = fuelPurchaseBySlice.actions;

export default fuelPurchaseBySlice.reducer;
