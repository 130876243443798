import { createSlice } from "@reduxjs/toolkit";

const selectedBasinsSlice = createSlice({
  name: "selectedBasins",
  initialState: {},
  reducers: {
    setSelectedBasins(state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedBasins } = selectedBasinsSlice.actions;

export default selectedBasinsSlice.reducer;
