import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPricescenarioData } from "../redux/reducers/selectedPriceScenario";
import AddIcon from "../assets/plus.svg";
import { addFuelPriceScenario, allPriceForecastScenario } from "../api/data";

const SelectPriceScenariotab = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );

  const [scenarioValue, setScenarioValue] = useState("");
  const [priceScenarios, setPriceScenarios] = useState([]);
  const [showPriceScenarioError, setShowPriceScenarioError] = useState(false);

  const getAllPriceScenarios = () => {
    allPriceForecastScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setPriceScenarios(scenario);
      })
      .catch((err) => console.log("error in price forcast scenario", err));
  };

  const handleAdd = () => {
    setScenarioValue("");
    if (scenarioValue.length !== 0 && scenarioValue[0] !== " ") {
      addScenario(scenarioValue);
    } else {
      handleNext();
    }
  };

  const addScenario = (name) => {
    addFuelPriceScenario(name)
      .then((res) => {
        getAllPriceScenarios();
        console.log(res.data);
        dispatch(
          setPricescenarioData({
            id: res.data.id,
            item: res.data.scenarioName,
          })
        );
        handleNext();
      })
      .catch((err) => console.log("add scenario err", err));
  };

  useEffect(() => {
    getAllPriceScenarios();
  }, []);

  const handleInputChange = (e) => {
    setScenarioValue(e.target.value);
  };

  const handleLegislativeScenarioChange = (item) => {
    dispatch(setPricescenarioData(item));
    handleNextStep();
  };

  const handleNext = () => {
    if (priceScenario.id === null) {
      setShowPriceScenarioError(true);
    } else {
      handleNextStep();
    }
  };
  return (
    <>
      <main className="p-0">

        <div className="legislativ-scenario-container">
          <div className="scenario-container mt-0 p-0 justify-content-end bg-transparent">
            <span className="d-none">OR</span>
            <div className="scenario-add-container d-none">
              <div className="input-container">
                <p className="input-label">Add Custom Name and Select</p>
                <div className="mail-input pos-relative parameter">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="scenario_name"
                    className="bg-white"
                    value={scenarioValue}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>

              <div className="add-btn">
                <button
                  className="btn btn-primary add"
                  // disabled={!scenarioValue}
                  onClick={handleAdd}
                >
                  <img src={AddIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="actions d-none">
          <button className="btn btn-primary" onClick={handleNext}>
            Next
          </button>
        </div>
      </main>
    </>
  );
};

export default SelectPriceScenariotab;
