import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import { useEffect, useState } from "react";
import {
  allVessels,
  getOwner,
  vesselById,
  fuelTankByVesselId,
  energyConsumptionByVesselId,
  enginesByVesselId,
} from "../api/data";
import SelectBox from "../components/SelectBox";
import BackArrow from "../assets/arrow-left.svg";
import EditBtnIcon from "../assets/editbtn.svg";
import { CommonOverlayModal } from "../components/CommonOverlayModal";
import { setSelectedVesselData } from "../redux/reducers/selectedVesselReducer";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeAllLetters, formatNumber } from "../utils/formatter";

const VesselEditor = () => {
  const dispatch = useDispatch();
  const [backArrowLink, setBackArrowLink] = useState("");
  const navigate = useNavigate();
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [showEnginesModal, setShowEnginesModal] = useState(false);
  const [modal, setModal] = useState("");
  const [vesselList, setVesselList] = useState([]);
  const [vesselInfo, setVesselInfo] = useState();
  const selectedVessel = useSelector(
    (state) => state.selectedVesselItem.selectedVessel
  );

  const [fuelTankInfo, setFuelTankInfo] = useState();
  const [energyConsumptionInfo, setEnergyConsumptionInfo] = useState();
  const [enginesInfo, setEnginesInfo] = useState();
  const vesselsDefaultImage =
    "https://storage.googleapis.com/vessel-images/default_vessel_image.jpg";
  const [changeVesselImg, setChangeVesselImg] = useState(vesselsDefaultImage);

  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl");
    setBackArrowLink(url);
  };

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const handleEditClick = (e) => {
    getEnginesById(selectedVessel.id);
    e.stopPropagation();
    setShowEnginesModal(true);
    setModal("engine");
  };

  const handleEditFuelTank = (e) => {
    getFuelTankById(selectedVessel.id);
    e.stopPropagation();
    setShowEnginesModal(true);
    setModal("fuel");
  };

  const handleEditEnergyConsumption = (e) => {
    getEnergyConsumptionById(selectedVessel.id);
    e.stopPropagation();
    setShowEnginesModal(true);
    setModal("energy");
  };

  const handleEngineCloseModal = async(id = null) => {
    setShowEnginesModal(false);
    await getAllVessels(id);
    // getVesselForId(vesselInfo?.id);
  };

  const getAllVessels = (id) => {
    allVessels()
      .then((result) => {
        const vessel = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.vesselName,
        }));
        setVesselList(vessel);
        const firstVessel = vessel[0];
        let currentVessel = firstVessel;
        if(id){
          currentVessel = vessel.find((vessel) => vessel.id === id)
        }
        else if (selectedVessel == null || selectedVessel?.id == null) {
          dispatch(setSelectedVesselData(firstVessel)); 
        } else {
          currentVessel = vessel.find(
            (vessel) => vessel.id === selectedVessel.id
          );
          currentVessel.item = selectedVessel?.item;
          dispatch(setSelectedVesselData(currentVessel));
        }
        getVesselForId(currentVessel.id);
        getFuelTankById(currentVessel.id);
        getEnergyConsumptionById(currentVessel.id);
        getEnginesById(currentVessel.id);
        setVesselImage(
          `https://storage.googleapis.com/vessel-images/vessel-image-${currentVessel.id}-thumbnail.png`
        );
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getVesselForId = (vesselId) => {
    const id = parseInt(vesselId);
    vesselById(id)
      .then((result) => {
        setVesselInfo(result.data);
        // dispatch(setSelectedVesselData(result.data));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getFuelTankById = (vesselId) => {
    const id = parseInt(vesselId);
    fuelTankByVesselId(id)
      .then((result) => {
        setFuelTankInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get fuel tanks", err);
      });
  };
  const getEnergyConsumptionById = (vesselId) => {
    const id = parseInt(vesselId);
    energyConsumptionByVesselId(id)
      .then((result) => {
        setEnergyConsumptionInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err getting energy Consumption", err);
      });
  };

  const getEnginesById = (vesselId) => {
    const id = parseInt(vesselId);
    enginesByVesselId(id)
      .then((result) => {
        setEnginesInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err getting engines", err);
      });
  };

  const handleSelectedVesselChange = (item) => {
    if (item?.id) {
      getVesselForId(item.id);
      getFuelTankById(item.id);
      getEnginesById(item.id);
      getEnergyConsumptionById(item.id);
      setVesselImage(
        `https://storage.googleapis.com/vessel-images/vessel-image-${item.id}-thumbnail.png`
      );
    } else {
      setVesselInfo(undefined);
    }
    dispatch(setSelectedVesselData(item));
    console.log("selected Vessel", selectedVessel)
  };

  const setVesselImage = (url) => {
    const img = new Image();

    img.onload = () => {
      console.log("Image loaded successfully:", url);
      setChangeVesselImg(url);
    };

    img.onerror = () => {
      console.log("Image not found or failed to load:", url);
      setChangeVesselImg(vesselsDefaultImage);
    };

    img.src = url;
  };

  useEffect(() => {
    getOwnerPermission();
    getAllVessels();
  }, []);

  const handleCompatibility = (sharedFamiles) => {
    let compatibility = "";
    sharedFamiles.forEach((sharedFamily, index) => {
      if (index === 0) {
        compatibility = sharedFamily?.fuelFamilyName;
      } else {
        compatibility += " / " + sharedFamily?.fuelFamilyName;
      }
    });
    return compatibility;
  };

  return (
    <div className="login-container h-100 splash-screen pb-5">
      <NavbarComponent showfaq={false} showVersion={true} show={true} />
      <main className="container-padding pt-4">
        <div>
          <div className="legislativ-scenario-container ">
            <div className="title">
              <a href={backArrowLink}>
                <button
                  className="btn btn-primary add"
                  onClick={() => handleBackUrl()}
                >
                  <img src={BackArrow} alt="" />
                </button>
              </a>
              <h1 className="mb-0 fs-28 text-dark d-flex">
                {ownerInfo?.owner?.name} Vessel Editor
                <span className="beta h-100 bg-primary ms-3">BETA</span>
              </h1>
              <div className="select-scenario d-flex ms-auto align-items-center">
                <p className="input-label mb-0 me-2">Select Vessel</p>
                <SelectBox
                  selectedItem={selectedVessel}
                  listItems={vesselList}
                  setSelectedItem={handleSelectedVesselChange}
                  disabledOptions={[selectedVessel]}
                  showError={false}
                  setShowError={() => {}}
                  showAddElement={true}
                  showPopupOnAddElement={true}
                  addElementOptionName="Add New Vessel"
                  className="select-box-v2"
                  identifier="vesseleditor"
                  showEditIcon={true}
                  vesselInfo={vesselInfo}
                  setPopup={setShowEnginesModal}
                  setPopupModal={setModal}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex gap-1 w-100 align-items-center mb-1">
              <p className="input-label mb-0">Vessel Type:</p>
              <span>{vesselInfo?.vesselType?.vesselTypeName || ""}</span>
            </div>
          </div>
          <div className="legislative-main-container justify-content-start col-md-4 d-flex mt-4 gap-4 w-100 align-items-end">
            <div className="d-flex">
              <p className="input-label mb-0 me-2 mb-1">Main Fuel:</p>
              <span>{vesselInfo?.mainPropulsion || ""}</span>
            </div>
            <div className="d-flex">
              <p className="input-label mb-0 me-2 mb-1">CapEx:</p>
              <span>{`$ ${formatNumber(vesselInfo?.extraCapex)}`}</span>
            </div>
            <div className="d-flex">
              <p className="input-label mb-0 me-2 mb-1">Rotation:</p>
              <span>{vesselInfo?.rotation ? vesselInfo?.rotation : "N/A"}</span>
            </div>
            <div className="d-flex gap-3">
              <div className="d-flex align-items-center form-check form-check-inline">
                <input
                  className="form-check-input mt-0 me-2"
                  type="checkbox"
                  checked={vesselInfo?.hasEGRSCR || false}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  EGR/SCR
                </label>
              </div>
              <div className="d-flex align-items-center form-check form-check-inline">
                <input
                  className="form-check-input mt-0 me-2"
                  type="checkbox"
                  checked={vesselInfo?.hasScrubber || false}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  Scrubber
                </label>
              </div>
            </div>
          </div>

          <div className="d-flex mt-4 gap-4 flex-sm-wrap flex-xl-nowrap justify-content-center">
            <div
              className="col-md-8 p-0 position-relative"
              style={{ width: "50rem" }}
            >
              <img
                src={changeVesselImg}
                className="rounded"
                alt="ZJoule"
                style={{ width: "100%", height: "35rem" }}
              />
            </div>

            <div className="d-flex flex-column" style={{ width: "100%" }}>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button shadow-none bg-transparent d-flex gap-2 fs-20 fw-bold text-dark"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Engines
                      <img
                        className="cursor-pointer"
                        src={EditBtnIcon}
                        alt=""
                        onClick={(e) => handleEditClick(e)}
                      />
                    </button>
                  </h2>

                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className={`accordion-body border-top-0 pt-0 ${
                        enginesInfo?.length > 0 ? `ht-9 overflow-y-auto` : ``
                      }`}
                    >
                      <table className="table table">
                        <thead>
                          <tr>
                            <th scope="col">Engine Type</th>
                            <th scope="col">Model Type</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enginesInfo?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {capitalizeAllLetters(
                                  item.est?.myEngineClass.etname
                                )}
                              </td>
                              <td>{item.est?.estname}</td>
                              <td>{item.installed}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button shadow-none bg-transparent d-flex gap-2 fs-20 fw-bold text-dark collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Fuel Tanks{" "}
                      <img
                        className="cursor-pointer"
                        src={EditBtnIcon}
                        alt=""
                        onClick={(e) => handleEditFuelTank(e)}
                      />
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className={`accordion-body border-top-0 pt-0 ${
                        fuelTankInfo?.length > 0 ? `ht-9 overflow-y-auto` : ``
                      }`}
                    >
                      <table className="table table">
                        <thead>
                          <tr>
                            <th scope="col">Tank Capacity</th>
                            <th scope="col">Compatibility</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fuelTankInfo?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.capacityM3}</td>
                              <td>
                                {handleCompatibility(item.sharedByFuelFamilies)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button shadow-none bg-transparent d-flex gap-2 fs-20 fw-bold text-dark collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Energy Consumption{" "}
                      <img
                        className="cursor-pointer"
                        src={EditBtnIcon}
                        alt=""
                        onClick={(e) => handleEditEnergyConsumption(e)}
                      />
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className={`accordion-body border-top-0 pt-0 ${
                        energyConsumptionInfo?.length > 0
                          ? `ht-17 overflow-y-auto`
                          : ``
                      }`}
                    >
                      <table className="table table">
                        <thead>
                          <tr>
                            <th scope="col">Activity</th>
                            <th scope="col">Type</th>
                            <th scope="col">Requirement/hr</th>
                          </tr>
                        </thead>
                        <tbody>
                          {energyConsumptionInfo?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.va?.name}</td>
                              <td>{item.est?.estname}</td>
                              <td>{item.powerReqKw} kW</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="accordion" id="accordionExample">
                             {accordionData.map((item, index) => (
                                <div className="accordion-item" key={item.id}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                    Engines
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse${index}`}
                                        >
                                         {item.title} 
                                            
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body border-top-0 pt-0">
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
            </div>
          </div>
        </div>
        <CommonOverlayModal
          showOverlayModal={showEnginesModal}
          onCloseOverlayModal={handleEngineCloseModal}
          modalSection={modal}
          setVesselInfo={setVesselInfo}
          vesselInfo={vesselInfo}
          fuelTankData={fuelTankInfo}
          energyConsumptionData={energyConsumptionInfo}
          engineData={enginesInfo}
        />
      </main>
    </div>
  );
};
export default VesselEditor;
